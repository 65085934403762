<template>
  <div v-if="model">
    <div>
      nw - {{ $t('coefficients.power_cost') }} <br />
      bt - {{ $t('coefficients.battery_cost') }} <br />
      ch5 - {{ $t('coefficients.ch5_cost') }} <br />
      ch15 - {{ $t('coefficients.ch15_cost') }} <br /><br />
    </div>
    <div v-if="model === 'rollo'">
      bl1 - Blende cm<br />
      bl2 - Blende base
      <br /><br />
      mp1 - Montageprofil cm <br />
      mp2 - Montageprofil base
      <br /><br />
      wk1 - Wandkassette cm <br />
      wk2 - Wandkassette base
      <br /><br />
      dk1 - Deckenkassette cm <br />
      dk2 - Deckenkassette base
      <br /><br />
      dt - DesignTrager
    </div>
    <div v-if="model === 'panel'">
      sb1 - Schiebewagen cm<br />
      sb2 - Schiebewagen base<br />
      <br />
      tr1 - Track2 cm<br />
      tr2 - Track2 base<br />
      <br />
      tr3 - Track3 cm<br />
      tr4 - Track3 base<br />
      <br />
      tr5 - Track4 cm<br />
      tr6 - Track4 base<br />
      <br />
      tr7 - Track5 cm<br />
      tr8 - Track5 base<br />
      <br />
      dt - DesignTrager
    </div>
    <div v-if="model === 'flow'">
      gt - Gewicht<br />
      <br />
      mt - Motor<br />
      <br />
      pr1 - Profil<br />
      pr2 - Profi2<br />
      pr3 - Profil3<br />
    </div>
    <div v-if="model === 'banner'">wt - Wandtrager<br /></div>
  </div>
</template>

<script>
export default {
  name: 'CoefficientDescription',
  props: {
    model: {
      default: ''
    }
  }
};
</script>

<style lang="scss"></style>
