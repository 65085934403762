<template>
  <div class="input__container" :style="{ width: 200 + 'px' }">
    <span class="input__label">
      {{ name }}
    </span>
    <masked-input
      :placeholder="placeholder"
      :value="value"
      :type="type"
      class="input__value"
      :mask="mask"
      @input="change($event)"
    />
  </div>
</template>

<script>
import MaskedInput from 'vue-masked-input';

export default {
  name: 'CustomMaskedInput',
  components: { MaskedInput },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    width: {
      type: Number
    },
    placeholder: {
      type: String
    },
    mask: {
      type: String
    },
    name: {
      default: '',
      type: String,
      required: true
    },
    value: {
      default: null,
      required: false
    },
    type: {
      default: 'text',
      type: String
    },
    debounce: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    change(value) {
      this.$emit('change', value);
    }
  }
};
</script>

<style lang="scss"></style>
