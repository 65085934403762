<template>
  <div class="faqs-container">
    <table v-if="faqs.length" class="faqs">
      <tr v-for="item in faqs" :key="item.id">
        <td class="faqs__data">
          <h2>{{ item.question }}</h2>
        </td>
        <td>
          <router-link :to="`/faq/${item.id}`">
            <img src="../../../assets/edit.svg" />
          </router-link>
        </td>
        <td>
          <router-link :to="`/faq/delete/${item.id}`">
            <img src="../../../assets/close.svg" />
          </router-link>
        </td>
      </tr>
    </table>
    <div v-else>Content loading</div>

    <router-link class="faq-add" to="/faq/register">
      <button class="button-submit register-btn" type="submit">+ {{ $t('faqs.add') }}</button>
    </router-link>
  </div>
</template>

<script>
import { api } from '@/api';

/**
 * @typedef faq
 * @type {object}
 * @property {number} id id faq
 * @property {string} question вопрос
 * @property {string} answer ответ
 * @property {string} type тип вопроса (по категории)
 */

export default {
  name: 'Faqs',
  data() {
    return {
      faqs: [],
      message: ''
    };
  },
  mounted() {
    api.setBearer(localStorage.getItem('token'));
    api
      .get('/faqs')
      .then((res) => {
        console.log('res', res);
        this.faqs = res.result;
      })
      .catch((error) => {
        this.message = `Error ${error}`;
      });
  }
};
</script>

<style lang="scss">
.faqs {
  width: 100%;

  &-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__data {
    display: flex;
    align-items: center;
  }

  &__info {
    color: var(--gray);
    font-size: 12px;
    margin-left: 10px;
  }
}

.register-btn {
  margin-top: 20px;
}
</style>
