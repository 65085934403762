<template>
  <div class="header">
    <div>
      <img src="../../assets/logo.svg" alt="logo" />
    </div>
    <div class="header__right">
      <div class="header__logout" @click="logout">
        <img src="../../assets/logout.svg" alt="cart" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'TheHeader',
  computed: {
    ...mapState(['auth', 'cart'])
  },
  methods: {
    showHandler(value) {
      this.$store.dispatch('filtersShow/showFilters', value);
    },
    getCartCount() {
      return this.cart.items.reduce((summ, item) => summ + item.quantity, 0);
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.go();
    }
  }
};
</script>

<style lang="scss">
.header {
  display: flex;
  align-items: center;
  padding: 20px 0;
  &__right {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  &__cart {
    position: relative;
    margin: 0 15px;
  }
  &__cart-count {
    position: absolute;
    top: 0;
    right: -5px;
    height: 15px;
    width: 15px;
    text-align: center;
    border-radius: 50%;
    color: var(--white);
    background-color: var(--gold);
  }
  &__logout {
    cursor: pointer;
  }
}
</style>
