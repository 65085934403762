<template>
  <div class="textarea input__container">
    <span class="input__label">
      {{ name }}
    </span>
    <textarea
      class="input__value"
      :type="type"
      :placeholder="placeholder"
      :value="value"
      @change="debounce ? dChange($event) : change($event)"
    ></textarea>
  </div>
</template>

<script>
import debounce from 'lodash-es/debounce';

export default {
  name: 'TextareaInput',
  props: {
    placeholder: {
      type: String
    },
    name: {
      default: '',
      type: String,
      required: true
    },
    value: {
      default: null,
      required: false
    },
    type: {
      default: 'text',
      type: String
    },
    debounce: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    change(e) {
      const value = String(e.target.value);

      if (this.value !== value) {
        this.$emit('change', value);
      }
      this.$forceUpdate();
    },
    // eslint-disable-next-line func-names
    dChange: debounce(function (e) {
      this.change(e);
    }, 300)
  }
};
</script>
<style lang="scss">
.textarea.input__container {
  width: 100%;
}
textarea.input__value {
  min-height: 40px;
  max-height: 300px;
  resize: vertical;
}
</style>
