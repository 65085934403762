<template>
  <div class="custom-checkbox" :class="{ checked: checked }" @click="toggle()">
    <div class="custom-checkbox__icon" />
    <checbox-icon :checked="checked" />
    <div class="custom-checkbox__label">
      {{ title }}
    </div>
  </div>
</template>

<script>
import ChecboxIcon from './ChecboxIcon.vue';

export default {
  name: 'CustomCheckbox',
  components: { ChecboxIcon },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: ['title', 'value'],
  computed: {
    checked() {
      return this.value;
    }
  },
  methods: {
    toggle() {
      this.$emit('change', !this.checked);
    }
  }
};
</script>

<style lang="scss">
.custom-checkbox {
  display: flex;
  justify-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  margin-right: 20px;
  align-items: center;
}

.custom-checkbox__label {
  color: var(--dark-gray);
  padding-left: 10px;
}
</style>
