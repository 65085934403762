<template>
  <div class="material">
    <form class="material__form" @submit.prevent="updateMaterial">
      <div class="material__line">
        <text-input
          ref="name"
          :width="350"
          :name="$t('materials.name')"
          :placeholder="$t('materials.name')"
          :value="model.name"
          @change="changeInput('name', $event)"
        />
        <text-input
          ref="code"
          :width="350"
          :name="$t('materials.code')"
          :placeholder="$t('materials.code')"
          :value="model.code"
          @change="changeInput('code', $event)"
        />
      </div>
      <div class="material__line">
        <number-input
          ref="maxWidth"
          :width="350"
          :name="$t('materials.maxWidth')"
          :placeholder="$t('materials.maxWidth')"
          :value="model.maxWidth"
          @change="changeInput('maxWidth', $event)"
        />
        <number-input
          ref="maxHeight"
          :width="350"
          :name="$t('materials.maxHeight')"
          :placeholder="$t('materials.maxHeight')"
          :value="model.maxHeight"
          @change="changeInput('maxHeight', $event)"
        />
      </div>
      <div class="material__line">
        <custom-checkbox v-model="model.perforation" :title="$t('materials.perforation')" />
        <custom-checkbox v-model="model.rotate" :title="$t('materials.rotate')" />
      </div>
      <div class="button-container">
        <button class="button-submit" type="submit">{{ $t('lk.save') }}</button>
      </div>
    </form>
    <div class="message">{{ message }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { api } from '@/api';
import TextInput from '@/components/inputs/text-input/TextInput';
import NumberInput from '@/components/inputs/number-input/NumberInput';
import CustomCheckbox from '@/components/inputs/custom-checkbox/CustomCheckbox';
import { errorHandler } from '@/mixins';

export default {
  name: 'Material',
  components: {
    NumberInput,
    TextInput,
    CustomCheckbox
  },
  mixins: [errorHandler],
  computed: {
    ...mapState(['auth', 'user'])
  },
  watch: {},
  data() {
    return {
      material_id: this.$route.params.id,
      message: '',
      model: ''
    };
  },
  mounted() {
    console.log('mounted Material');
    api
      .get(`/materials/${this.material_id}`)
      .then((res) => {
        console.log('res', res);
        if (res.error) {
          this.message = `Error ${res.error}`;
          return;
        }
        this.model = res.result;
        console.log('model', this.model);
      })
      .catch((error) => {
        this.message = `Error ${error}`;
      });
  },
  methods: {
    changeInput(key, value) {
      console.log('changeInput', key);
      this.model[key] = value;
      this.message = '';
    },
    updateMaterial() {
      console.log('updateMaterial');
      if (!this.validateForm()) {
        return;
      }
      api
        .patch(`/materials/${this.material_id}`, JSON.stringify(this.model))
        .then((res) => {
          if (res.message) return this.messageHandler(`Error ${res.message}`);
          console.log('res', res);
          window.setTimeout(this.redirectToMaterials, 1000);
          return this.messageHandler(this.$t('materials.saveded_successfully'));
        })
        .catch((error) => {
          this.messageHandler(`Error ${error}`);
        });
    },
    validateForm() {
      console.log('validateForm');
      this.message = '';
      if (!this.model.name) {
        this.messageHandler(`${this.$t('validate_required')} "${this.$t('materials.name')}"`);
        this.$refs.name.focus();
        return false;
      }
      if (!this.model.code) {
        this.messageHandler(`${this.$t('validate_required')} "${this.$t('materials.code')}"`);
        this.$refs.code.focus();
        return false;
      }
      console.log('validation OK');
      return true;
    },
    redirectToMaterials() {
      console.log('redirectToMaterials');
      window.location.href = `${window.location.origin}/materials`;
    }
  }
};
</script>

<style lang="scss">
.button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.material {
  .message {
    padding-top: 20px;
  }

  &__form {
  }

  &__line {
    display: flex;
  }
}
</style>
