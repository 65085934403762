import { dutchedVersion } from '@/config';

const allCountries = [
  {
    code: 'at',
    name: 'Austria',
    vat: 20
  },
  {
    code: 'by',
    name: 'Belarus',
    vat: 20
  },
  {
    code: 'be',
    name: 'Belgium',
    vat: 21
  },
  {
    code: 'bg',
    name: 'Bulgaria',
    vat: 20
  },
  {
    code: 'gb',
    name: 'UK',
    vat: 20
  },
  {
    code: 'hu',
    name: 'Hungary',
    vat: 27
  },
  {
    code: 'dk',
    name: 'Denmark',
    vat: 25
  },
  {
    code: 'de',
    name: 'Germany',
    vat: 19
  },
  {
    code: 'gr',
    name: 'Greece',
    vat: 24
  },
  {
    code: 'ie',
    name: 'Ireland',
    vat: 23
  },
  {
    code: 'es',
    name: 'Spain',
    vat: 21
  },
  {
    code: 'it',
    name: 'Italy',
    vat: 22
  },
  {
    code: 'cy',
    name: 'Cyprus',
    vat: 19
  },
  {
    code: 'lv',
    name: 'Latvia',
    vat: 21
  },
  {
    code: 'lt',
    name: 'Lithuania',
    vat: 21
  },
  {
    code: 'lu',
    name: 'Luxembourg',
    vat: 17
  },
  {
    code: 'mt',
    name: 'Malta',
    vat: 18
  },
  {
    code: 'nl',
    name: 'Netherlands',
    vat: 21
  },
  {
    code: 'pl',
    name: 'Poland',
    vat: 23
  },
  {
    code: 'pt',
    name: 'Portugal',
    vat: 23
  },
  {
    code: 'ru',
    name: 'Russia',
    vat: 20
  },
  {
    code: 'ro',
    name: 'Romania',
    vat: 19
  },
  {
    code: 'sk',
    name: 'Slovakia',
    vat: 20
  },
  {
    code: 'si',
    name: 'Slovenia',
    vat: 22
  },
  {
    code: 'ua',
    name: 'Ukraine',
    vat: 20
  },
  {
    code: 'fi',
    name: 'Finland',
    vat: 24
  },
  {
    code: 'fr',
    name: 'France',
    vat: 20
  },
  {
    code: 'hr',
    name: 'Croatia',
    vat: 22
  },
  {
    code: 'se',
    name: 'Sweden',
    vat: 25
  },
  {
    code: 'cz',
    name: 'Czech Republic',
    vat: 21
  },
  {
    code: 'ee',
    name: 'Estonia',
    vat: 20
  }
];

const onlyRussia = [
  {
    code: 'ru',
    name: 'Russia',
    vat: 20
  }
];

export default dutchedVersion ? allCountries : onlyRussia;
