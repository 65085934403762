<template>
  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.31479 7.85849L0.132955 4.44128C-0.0443184 4.25327 -0.0443184 3.94725 0.132955 3.75723L0.775686 3.07419C0.95296 2.88618 1.24114 2.88618 1.41842 3.07419L3.63661 5.46634L8.58118 0.141009C8.75846 -0.0470029 9.04664 -0.0470029 9.22391 0.141009L9.86664 0.825052C10.0439 1.01306 10.0439 1.32008 9.86664 1.50709L3.95752 7.85849C3.78025 8.0465 3.49206 8.0465 3.31479 7.85849Z"
      :fill="checked ? '#DABE96' : 'none'"
    />
  </svg>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      required: true,
      default: false
    }
  }
};
</script>

<style lang="scss"></style>
