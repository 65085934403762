import Vue from 'vue';
import i18n from '@/localization';
import store from '@/store';
import router from '@/router';
import App from './App';

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
  i18n
}).$mount('#app');
