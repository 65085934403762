<template>
  <div>
    <div class="header">
      <div>
        <img src="../../assets/logo.svg" alt="logo" />
      </div>
      <div class="header__link">
        <router-link :to="`/profile/${auth.id}`">Account</router-link>
        <img src="../../assets/logout.svg" alt="cart" @click="logout" />
      </div>
    </div>
    <div class="menu">
      <div v-if="configurator" class="menu-item">
        <a :href="linkConfigurator">
          <img src="../../assets/constructor.svg" alt="configurator" />
          <br />
          configurator
        </a>
      </div>
      <div v-if="library" class="menu-item">
        <a :href="linkLibrary">
          <img src="../../assets/dictionary.svg" />
          <br />
          medialibrary
        </a>
      </div>
      <!--      <div class="menu-item">
        <a>
          <img src="../../assets/sertificate.svg" alt="sertificate" />
          <br />
          warranty certificates</a>
      </div>-->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { CONSTRUCTOR_URL, LIBRARY_URL } from '@/config';
import { api } from '@/api';

export default {
  name: 'Navigation',
  computed: {
    ...mapState(['auth']),
    linkConfigurator() {
      return this.configurator ? `${this.CONSTRUCTOR_URL}/?token=${this.auth.token}` : null;
    },
    linkLibrary() {
      return this.library ? `${this.LIBRARY_URL}/?token=${this.auth.token}` : null;
    }
  },
  created() {
    this.CONSTRUCTOR_URL = CONSTRUCTOR_URL;
    this.LIBRARY_URL = LIBRARY_URL;
  },
  mounted() {
    api.setBearer(localStorage.getItem('token'));
    api
      .get(`/users/${this.auth.id}`)
      .then((res) => {
        this.configurator = res.configurator;
        this.library = res.library;
      })
      .catch((error) => {
        console.log(error);
        this.message = `Ошибка ${error}`;
      });
  },
  beforeUpdate() {
    if (!this.auth.token) {
      this.$router.replace('/auth');
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
    }
  },
  data() {
    return {
      configurator: false,
      library: false
    };
  }
};
</script>

<style lang="scss" scoped>
.header {
  margin: 10px var(--indentation-side);
  justify-content: space-between;

  &__link {
    display: flex;
    align-items: center;

    a {
      padding: 10px;
    }
  }
}

.router-link-active {
  background: none !important;
  color: var(--dark-gray);
}

.menu {
  height: 100vh;
  display: flex;
  flex-direction: row;
  background-image: url('./../../assets/bg.png');
  background-size: cover;
  color: #ffffff;
}

.menu-item {
  height: 100vh;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 40px;
  line-height: 140%;
  flex-basis: 32%;
  word-break: break-word;
  padding: 0 70px;
  align-items: center;
  text-align: left;

  a {
    text-decoration: none;
    color: inherit;
  }
}

.menu-item:hover {
  background-color: #bb9761;
}

@media (max-width: 1700px) {
  .menu-item {
    font-size: 35px;
  }
}

@media (max-width: 1500px) {
  .menu-item {
    font-size: 30px;
  }
}

@media (max-width: 1250px) {
  .menu-item {
    font-size: 28px;
  }
}

@media (max-width: 750px) {
  .menu {
    flex-direction: column;
  }
  .menu-item {
    font-size: 12px;
    white-space: nowrap;
    justify-content: center;
    text-align: center;
    flex-grow: inherit;
  }
}
</style>
