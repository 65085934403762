<template>
  <div class="materials-container">
    <table class="materials">
      <tr v-for="(item, index) in materials" :key="index">
        <td class="materials__data">
          <h2>{{ item.name }}</h2>
        </td>
        <td>
          <router-link :to="`/material/${item.id}`">
            <img src="../../../assets/edit.svg" />
          </router-link>
        </td>
        <td>
          <router-link :to="`/material/delete/${item.id}`">
            <img src="../../../assets/close.svg" />
          </router-link>
        </td>
      </tr>
    </table>

    <router-link class="material-add" to="/material/register">
      <button class="button-submit register-btn" type="submit">+ {{ $t('materials.add') }}</button>
    </router-link>
  </div>
</template>

<script>
import models from '@/dictionary/models';
import { api } from '@/api';
import { mapState } from 'vuex';
import { collections } from '@/config';

export default {
  name: 'Coefficients',
  components: {},
  data() {
    return {
      materials: [],
      message: ''
    };
  },
  created() {
    this.options = {
      collections,
      models
    };
  },
  computed: {
    ...mapState(['rate'])
  },
  mounted() {
    api.setBearer(localStorage.getItem('token'));
    api
      .get('/materials')
      .then((res) => {
        console.log('res', res);
        this.materials = res.result;
      })
      .catch((error) => {
        this.message = `Error ${error}`;
      });
  },
  methods: {
    changeInput(key, value) {
      this[key] = value;
      this.message = '';
    },
    viewFilters(key, value) {
      this[key] = value;
      if (this.getCoefficientsChange) {
        this.$store.dispatch('rate/getData', {
          model: this.model,
          collection: this.collection
        });
      }
    }
  }
};
</script>

<style lang="scss">
.materials {
  width: 100%;

  &-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__data {
    display: flex;
    align-items: center;
  }

  &__info {
    color: var(--gray);
    font-size: 12px;
    margin-left: 10px;
  }
}

.register-btn {
  margin-top: 20px;
}
</style>
