<template>
  <div class="color">
    <form class="color__form" @submit.prevent="registerColor">
      <div class="color__line">
        <text-input
          :value="model.name"
          :name="$t('colors.name')"
          :placeholder="$t('colors.name')"
          :width="240"
          @change="model.name = $event"
        />
        <text-input
          :value="model.value"
          :name="$t('colors.code')"
          :placeholder="$t('colors.code')"
          :width="240"
          @change="model.value = $event"
        />
      </div>
      <div class="color__line">
        <multiple-select
          :name="$t('colors.category')"
          :options="prepareOptions(types)"
          :value="model.types"
          class="color__select"
          @change="model.types = $event"
        />
      </div>
      <div class="button-container">
        <button class="button-submit" type="submit">{{ $t('lk.save') }}</button>
      </div>
    </form>
    <div class="message">{{ message }}</div>
  </div>
</template>

<script>
import { api } from '@/api';
import { errorHandler } from '@/mixins';
import { allTypes } from '@/components/admin-panel/color/types';
import TextInput from '../../inputs/text-input/TextInput.vue';
import MultipleSelect from '../../inputs/multiple-select/MultipleSelect.vue';

export default {
  name: 'ColorRegister',
  components: { TextInput, MultipleSelect },
  mixins: [errorHandler],
  data() {
    return {
      types: allTypes,
      message: '',
      model: {
        types: [],
        name: '',
        value: ''
      }
    };
  },
  methods: {
    registerColor() {
      const data = this.prepareModelTypesAfterUpdate(this.model);

      api
        .post('/colors', JSON.stringify(data))
        .then((res) => {
          if (res.message) return this.messageHandler(`Error ${res.message}`);
          window.setTimeout(this.redirectToColors, 1000);
          return this.messageHandler(this.$t('colors.saveded_successfully'));
        })
        .catch((error) => {
          this.messageHandler(`Error ${error}`);
        });
    },
    redirectToColors() {
      window.location.href = `${window.location.origin}/colors`;
    },
    prepareOptions(types) {
      return types.map((type) => {
        return {
          id: type.value,
          name: type.lang[this.$i18n.locale.slice(0, 2)]
        };
      });
    },
    prepareValue() {
      const items = this.types.filter((type) => this.model.types.includes(type.value));
      return this.prepareOptions(items);
    },
    prepareModelTypesAfterUpdate(m) {
      const model = { ...m };
      const typesId = model.types.map((item) => item.id);
      model.types = typesId;
      return model;
    }
  }
};
</script>

<style lang="scss">
.button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.color {
  .message {
    padding-top: 20px;
  }

  &__form {
  }

  &__category-add {
    margin-bottom: 20px;
  }

  &__line {
    display: flex;
    margin: 0px 0px 20px 0px;
    .input__container {
      margin-bottom: 0;
    }
  }
  &__col {
    display: flex;
    flex-direction: column;
    max-width: 700px;
  }
  &__select {
    width: 240px;
  }
}
</style>
