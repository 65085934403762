<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.512 8.89002C10.74 8.65122 11.172 8.39922 12 8.39922C12.828 8.39922 13.26 8.65242 13.488 8.89002C13.728 9.14202 13.8 9.43722 13.8 9.59922C13.8 10.144 13.476 10.4368 12.9324 10.708C12.7969 10.7741 12.6597 10.8365 12.5208 10.8952L12.4944 10.9072C12.3628 10.9616 12.2332 11.0205 12.1056 11.0836C11.9553 11.1546 11.8159 11.2468 11.6916 11.3572C11.6012 11.4382 11.5286 11.5371 11.4785 11.6476C11.4283 11.7581 11.4015 11.8779 11.4 11.9992V13.1992C11.4 13.3583 11.4632 13.511 11.5757 13.6235C11.6883 13.736 11.8409 13.7992 12 13.7992C12.1591 13.7992 12.3117 13.736 12.4243 13.6235C12.5368 13.511 12.6 13.3583 12.6 13.1992V12.1792L12.6444 12.1552C12.7344 12.1096 12.8436 12.0628 12.984 12.0028L12.9972 11.9968C13.1376 11.9356 13.3008 11.8648 13.4676 11.7808C14.124 11.4568 15 10.8556 15 9.59922C15 9.16122 14.832 8.55642 14.352 8.05842C13.86 7.54722 13.092 7.19922 12 7.19922C10.908 7.19922 10.14 7.54602 9.648 8.05842C9.168 8.55642 9 9.16122 9 9.59922C9 9.75835 9.06321 9.91096 9.17574 10.0235C9.28826 10.136 9.44087 10.1992 9.6 10.1992C9.75913 10.1992 9.91174 10.136 10.0243 10.0235C10.1368 9.91096 10.2 9.75835 10.2 9.59922C10.2 9.43722 10.272 9.14202 10.512 8.89002ZM12 16.7992C12.2387 16.7992 12.4676 16.7044 12.6364 16.5356C12.8052 16.3668 12.9 16.1379 12.9 15.8992C12.9 15.6605 12.8052 15.4316 12.6364 15.2628C12.4676 15.094 12.2387 14.9992 12 14.9992C11.7613 14.9992 11.5324 15.094 11.3636 15.2628C11.1948 15.4316 11.1 15.6605 11.1 15.8992C11.1 16.1379 11.1948 16.3668 11.3636 16.5356C11.5324 16.7044 11.7613 16.7992 12 16.7992Z"
      fill="#444444"
      stroke="#444444"
      stroke-width="0.5"
    />
    <rect x="4" y="4" width="16" height="16" rx="2" stroke="#444444" stroke-width="2" />
  </svg>
</template>

<script>
export default {
  name: 'IconFaq'
};
</script>

<style lang="scss"></style>
