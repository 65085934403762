export const CONSTRUCTOR_URL = 'http://conceptm-new.dvkr.xyz';
export const LIBRARY_URL = 'http://conceptm-medialibrary.dvkr.xyz';
export const API_URL = 'https://conceptm.dvkr.xyz:3000/api';

export const dutchedVersion = false;

export const collections = [
  {
    value: 'Elegance',
    name: 'Elegance',
    combi: ['flow']
  },
  {
    value: 'Excellence',
    name: 'Excellence',
    combi: ['rollo', 'flow', 'banner', 'panel']
  },
  {
    value: 'Exquisite',
    name: 'Exquisite',
    combi: ['rollo', 'flow', 'banner', 'panel']
  },
  {
    value: 'Style',
    name: 'Style'
  },
  {
    value: 'Select',
    name: 'Select'
  },
  {
    value: 'Superb',
    name: 'Superb',
    combi: ['rollo', 'flow', 'banner', 'panel']
  }
];
