<template>
  <div class="hide-panel">
    <form class="hide-panel__form" @submit.prevent="updateHidePanel">
      <div class="hide-panel__line">
        <text-input
          :value="model.name"
          :name="$t('hidePanels.name')"
          :placeholder="$t('hidePanels.name')"
          :width="240"
          @change="model.name = $event"
        />
        <text-input
          :value="model.code"
          :name="$t('hidePanels.code')"
          :placeholder="$t('hidePanels.code')"
          :width="240"
          @change="model.code = $event"
        />
      </div>
      <div class="button-container">
        <button class="button-submit" type="submit">{{ $t('lk.save') }}</button>
      </div>
    </form>
    <div class="message">{{ message }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { api } from '@/api';
import { errorHandler } from '@/mixins';
import TextInput from '../../inputs/text-input/TextInput.vue';

export default {
  name: 'HidePanel',
  components: { TextInput },
  mixins: [errorHandler],
  data() {
    return {
      hidePanel_id: this.$route.params.id,
      message: '',
      model: {
        name: '',
        code: ''
      }
    };
  },
  computed: {
    ...mapState(['auth', 'user'])
  },
  mounted() {
    api.get(`/hide-panels/${this.hidePanel_id}`).then((res) => {
      if (res.error) {
        this.message = `Error ${res.error}`;
        return;
      }
      this.model = res.result;
    });
  },
  methods: {
    updateHidePanel() {
      const data = { ...this.model };

      api
        .patch(`/hide-panels/${this.hidePanel_id}`, JSON.stringify(data))
        .then((res) => {
          if (res.message) return this.messageHandler(`Error ${res.message}`);
          window.setTimeout(this.redirectToHidePanels, 1000);
          return this.messageHandler(this.$t('hidePanels.saveded_successfully'));
        })
        .catch((error) => {
          this.messageHandler(`Error ${error}`);
        });
    },
    redirectToHidePanels() {
      window.location.href = `${window.location.origin}/hide-panels`;
    }
  }
};
</script>

<style lang="scss">
.button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.delete-material-btn {
  border: 0;
}

.delete-material-btn:hover {
  cursor: pointer;
}

.hide-panel {
  // .hide-panel__form
  &__form {
  }
  // .hide-panel__line
  &__line {
    margin: 0px 0px 20px 0px;
    .input__container {
      margin-bottom: 0;
    }
  }
  // .hide-panel__select
  &__select {
    width: 240px;
  }
}
</style>
