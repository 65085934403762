<template>
  <div class="app-body">
    <div class="app-header">
      <the-header />
      <h1>{{ $t('lk.title') }}</h1>
    </div>
    <div class="main">
      <the-nav-admin />
      <admin-panel />
    </div>
  </div>
</template>

<script>
import AdminPanel from '@/router/views/AdminPanel';
import TheNavAdmin from '@/components/ui/TheNavAdmin';
import TheHeader from '@/components/ui/TheHeader';

export default {
  name: 'MainLayout',
  components: { TheHeader, TheNavAdmin, AdminPanel }
};
</script>

<style lang="scss">
@import './../../design';

.app-body {
  width: 100%;
  min-height: 100vh;
  background: var(--bg-gray);
  overflow-x: hidden;
}

.app-header {
  // display: flex;
  margin: 18px var(--indentation-side);
}

.main {
  display: flex;
  margin: 0 var(--indentation-side);
}
</style>
