<template>
  <div>
    <ul class="nav__items">
      <router-link tag="li" :to="`/profile/${auth.id}`">
        <icon-profile />{{ $t('profile.title') }}</router-link
      >
      <router-link v-show="auth.role === 'admin'" tag="li" to="/coefficients">
        <icon-rate />{{ $t('coefficients.title') }}</router-link
      >
      <router-link v-show="auth.role === 'admin'" tag="li" to="/currency">
        <icon-course />{{ $t('currency.title') }}</router-link
      >
      <router-link v-show="auth.role === 'admin'" tag="li" to="/users">
        <icon-users />{{ $t('users.title') }}</router-link
      >
      <router-link
        v-show="auth.role === 'admin' && $route.params.id === 'register'"
        tag="li"
        to="/user/register"
      >
        <icon-profile />{{ $t('registration.title') }}</router-link
      >
      <router-link v-show="auth.role === 'admin'" tag="li" to="/materials">
        <icon-materials />{{ $t('materials.title') }}
      </router-link>
      <router-link v-show="auth.role === 'admin'" tag="li" to="/collections">
        <icon-collections />{{ $t('collections.title') }}
      </router-link>
      <router-link v-show="auth.role === 'admin'" tag="li" to="/faqs">
        <icon-faq />{{ $t('faqs.title') }}
      </router-link>
      <router-link v-show="auth.role === 'admin'" tag="li" to="/colors">
        <icon-colors />{{ $t('colors.title') }}
      </router-link>
      <router-link v-show="auth.role === 'admin'" tag="li" to="/hide-panels">
        <icon-rate />{{ $t('hidePanels.title') }}
      </router-link>
      <router-link
        v-show="
          auth.role === 'admin' &&
          $route.fullPath.includes('/user/') &&
          $route.params.id !== 'register'
        "
        tag="li"
        :to="`/user/${$route.params.id}`"
      >
        <icon-profile />{{ $t('edit.title') }}</router-link
      >
      <!--        <a @click="logout">Выход</a>-->
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import IconCourse from '@/components/ui/icons/IconCourse';
import IconRate from '@/components/ui/icons/IconRate';
import IconProfile from '@/components/ui/icons/IconProfile';
import IconUsers from '@/components/ui/icons/IconUsers';
import IconMaterials from '@/components/ui/icons/IconMaterials';
import IconColors from '@/components/ui/icons/IconColors';
import IconCollections from '@/components/ui/icons/IconCollections';
import IconFaq from '@/components/ui/icons/IconFaq';

export default {
  name: 'TheNavAdmin',
  components: {
    IconUsers,
    IconProfile,
    IconRate,
    IconCourse,
    IconMaterials,
    IconColors,
    IconCollections,
    IconFaq
  },
  computed: {
    ...mapState(['auth'])
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/');
    }
  }
};
</script>

<style lang="scss">
@import './../../design';

.nav__items {
  display: flex;
  flex-direction: column;
  li {
    width: $nav-admin-width;
    background: var(--white);
    padding: 25px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    font-weight: 600;
    svg {
      padding-right: 10px;
    }
  }
}

.router-link-active {
  background: var(--gold) !important;
  color: var(--white);
  svg {
    path {
      stroke: var(--white);
    }
  }
}
</style>
