export default class FetchClient {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.headers = {
      Accept: 'application/json',
      withCredentials: true,
      credentials: 'include',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS'
    };
  }

  setBearer(authToken) {
    this.headers.Authorization = `Bearer ${authToken}`;
  }

  clearBearer() {
    delete this.headers.Authorization;
  }

  async fetch(method, url, body) {
    const r = await fetch(`${this.baseUrl}${url}`, {
      headers: this.headers,
      method,
      body
    });
    return r.json();
  }

  async get(url) {
    return this.fetch('GET', url);
  }

  async post(url, body) {
    return this.fetch('POST', url, body);
  }

  async put(url, body) {
    return this.fetch('PUT', url, body);
  }

  async patch(url, body) {
    return this.fetch('PATCH', url, body);
  }

  async delete(url) {
    return this.fetch('DELETE', url);
  }
}
