<template>
  <div class="activate-container">
    <div class="activate-form">
      <div class="activate-form__confirm">
        <form class="activate-form__body" @submit.prevent="activateDistributor">
          <img alt="logo" src="../../assets/logo.svg" />
          <br />
          <h3 class="activate-form__title">Distributor activation</h3>
          <div class="activate-form__input-container">
            <text-input
              ref="email"
              class="activate-form__input"
              :width="330"
              name="email"
              type="email"
              placeholder="email"
              :value="email"
              @change="changeInput('email', $event)"
            />
          </div>
          <div class="activate-form__input-container">
            <text-input
              ref="fname"
              class="activate-form__input"
              :width="330"
              name="fname"
              placeholder="fname"
              :value="fname"
              @change="changeInput('fname', $event)"
            />
          </div>
          <div class="activate-form__input-container">
            <text-input
              ref="sname"
              class="activate-form__input"
              :width="330"
              name="sname"
              placeholder="sname"
              :value="sname"
              @change="changeInput('sname', $event)"
            />
          </div>
          <div class="activate-form__input-container">
            <text-input
              ref="password"
              class="activate-form__input"
              :width="330"
              name="password"
              type="password"
              placeholder="password"
              :value="password"
              @change="changeInput('password', $event)"
            />
          </div>
          <div class="activate-form__additional">
            <button class="button-submit activate-form__submit" type="submit">Submit</button>
          </div>
        </form>
        <div class="activate-form__message">{{ this.message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TextInput from '@/components/inputs/text-input/TextInput';
import { api } from '@/api';

export default {
  name: 'DistributorActivate',
  components: {
    TextInput
  },
  data() {
    return {
      hash: this.$route.params.hash,
      email: '',
      fname: '',
      sname: '',
      password: '',
      message: ''
    };
  },
  computed: {
    ...mapState(['auth'])
  },
  watch: {
    'auth.token': {
      handler: function userRedirectHandler() {
        this.userRedirect();
      },
      deep: true
    }
  },
  created() {
    console.log('distributor activate');
    this.getRegistration();
  },
  methods: {
    changeInput(key, value) {
      this[key] = value;
    },
    viewFilters(key, value) {
      this[key] = value;
    },
    validateForm() {
      console.log('validateForm');
      this.message = '';
      if (this.email === '') {
        this.message = 'please set a email';
        this.$refs.email.focus();
        return false;
      }
      if (this.fname === '') {
        this.message = 'please set a first name';
        this.$refs.fname.focus();
        return false;
      }
      if (this.sname === '') {
        this.message = 'please set a last name';
        this.$refs.sname.focus();
        return false;
      }
      if (this.password === '') {
        this.message = 'please set a password';
        this.$refs.password.focus();
        return false;
      }
      console.log('validation OK');
      return true;
    },
    getRegistration() {
      console.log('getRegistration', this.hash);
      this.message = '';
      api
        .get(`/reg/${this.hash}`)
        .then((res) => {
          console.log('res', res);
          if (!res || !res.result) {
            this.message = 'Failed to get Registration';
          }
          if (res.result.activatedAt) {
            this.message = 'Distributor is already activated';
          }
          this.email = res.result.email;
          console.log('email', this.email);
          this.fname = res.result.userData.extendedData.contact_person_fname;
          console.log('fname', this.fname);
          this.sname = res.result.userData.extendedData.contact_person_sname;
          console.log('sname', this.sname);
        })
        .catch((error) => {
          console.error('error', error);
          this.message = 'Failed to get Registration';
        });
    },
    activateDistributor() {
      console.log('activateDistributor', this.hash);
      if (!this.validateForm()) {
        return;
      }
      api
        .post(
          '/reg/activate',
          JSON.stringify({
            hash: this.hash,
            userData: {
              email: this.email,
              fname: this.fname,
              sname: this.sname,
              password: this.password
            }
          })
        )
        .then((res) => {
          console.log('res', res);
          if (res.message) {
            this.message = res.message;
            return;
          }
          if (!res.result) {
            this.message = 'Failed to activate distributor';
            return;
          }
          this.message = 'Distributor activated successfully';
          window.setTimeout(this.redirectToAuth, 3000);
        })
        .catch((error) => {
          console.error('error', error);
          this.message = error;
        });
    },
    redirectToAuth() {
      console.log('redirectToAuth');
      window.location.href = `${window.location.origin}/auth`;
    }
  }
};
</script>

<style lang="scss">
.activate-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  background-image: url('./../../assets/bg.png');
  background-size: cover;
}

.activate-form {
  margin: 150px auto;
  width: 450px;

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__submit {
    width: 100px;
  }

  &__message {
    min-height: 36px;
    padding-top: 20px;
  }

  &__label {
    position: absolute;
    // position: relative;
    top: -10px;
    left: 100px;
    color: gray;
    background: white;
    font-size: 10px;
    padding: 0 10px;
    text-transform: lowercase;
    margin: 0 auto;
  }

  &__confirm {
    background-color: #fff;
    padding: 50px;
  }

  &__title {
    color: #191919;
    padding-bottom: 20px;
  }

  &__input {
    border: none;
    outline: none;
    // padding: 10px;
    text-align: center;
    // margin: 10px 0;
  }

  &__additional {
    display: flex;
    align-items: flex-end;
    width: 235px;
    justify-content: space-between;
  }

  &__recover-passw {
    text-decoration: underline;
    font-size: 12px;
  }
}

.button-submit {
  background: #dabe96;
  height: 40px;
  width: max-content;
  padding: 10px;

  border: 0;

  font-size: 14px;

  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #ffffff;
  &:disabled {
    background: var(--light-gray);
  }
}

.button-submit:hover {
  cursor: pointer;
}
</style>
