<template>
  <div class="admin-panel">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'AdminPanel'
};
</script>

<style lang="scss">
.admin-panel {
  position: relative;
  background: var(--white);
  height: 100%;
  width: 100%;
  margin-left: var(--indentation-side);
  padding: 40px;
}
</style>
