import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Auth from '@/router/views/Auth';
import Profile from '@/components/admin-panel/Profile';
import Currency from '@/components/admin-panel/Currency';
import Coefficients from '@/components/admin-panel/Coefficients';
import Collection from '@/components/admin-panel/collection/Collection';
import CollectionDelete from '@/components/admin-panel/collection/CollectionDelete';
import CollectionEdit from '@/components/admin-panel/collection/CollectionEdit';
import Collections from '@/components/admin-panel/collection/Collections';
import Material from '@/components/admin-panel/material/Material';
import MaterialDelete from '@/components/admin-panel/material/MaterialDelete';
import MaterialEdit from '@/components/admin-panel/material/MaterialEdit';
import Materials from '@/components/admin-panel/material/Materials';
import Home from '@/router/views/Home';
import Navigation from '@/router/views/Navigation';
import Users from '@/components/admin-panel/user/Users';
import User from '@/components/admin-panel/user/User';
import UserDelete from '@/components/admin-panel/user/UserDelete';
import Distributor from '@/components/admin-panel/distributor/Distributor';
import DistributorEdit from '@/components/admin-panel/distributor/DistributorEdit';
import DistributorActivate from '@/router/views/DistributorActivate';
import Faqs from '@/components/admin-panel/faq/Faqs';
import FaqRegister from '@/components/admin-panel/faq/FaqRegister';
import FaqDelete from '@/components/admin-panel/faq/FaqDelete';
import FaqEdit from '@/components/admin-panel/faq/FaqEdit';
import FaqCategory from '@/components/admin-panel/faq/FaqCategory';
import Colors from '@/components/admin-panel/color/Colors';
import ColorRegister from '@/components/admin-panel/color/ColorRegister';
import ColorDelete from '@/components/admin-panel/color/ColorDelete';
import ColorEdit from '@/components/admin-panel/color/ColorEdit';
import HidePanels from '@/components/admin-panel/hide-panel/HidePanels';
import HidePanelRegister from '@/components/admin-panel/hide-panel/HidePanelRegister';
import HidePanelDelete from '@/components/admin-panel/hide-panel/HidePanelDelete';
import HidePanelEdit from '@/components/admin-panel/hide-panel/HidePanelEdit';

Vue.use(VueRouter);

const routes = [
  {
    path: '/auth',
    name: 'auth',
    component: Auth
  },
  {
    path: '/distributor/activate/:hash',
    name: 'distributor-activate',
    component: DistributorActivate
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/profile/:id',
        name: 'profile',
        component: Profile
      },
      {
        path: '/currency',
        name: 'currency',
        component: Currency
      },
      {
        path: '/users',
        name: 'users',
        component: Users
      },
      {
        path: '/user/:id',
        name: 'user',
        component: User
      },
      {
        path: '/user/register',
        name: 'user-register',
        component: User
      },
      {
        path: '/user/delete/:id',
        name: 'user-delete',
        component: UserDelete
      },
      {
        path: '/distributor/register',
        name: 'distributor-register',
        component: Distributor
      },
      {
        path: '/distributor/:id',
        name: 'distributor',
        component: DistributorEdit
      },
      {
        path: '/coefficients',
        name: 'coefficients',
        component: Coefficients
      },
      {
        path: '/materials',
        name: 'materials',
        component: Materials
      },
      {
        path: '/material/register',
        name: 'material-register',
        component: Material
      },
      {
        path: '/material/:id',
        name: 'material',
        component: MaterialEdit
      },
      {
        path: '/material/delete/:id',
        name: 'material-delete',
        component: MaterialDelete
      },
      {
        path: '/collections',
        name: 'collections',
        component: Collections
      },
      {
        path: '/collection/register',
        name: 'collection-register',
        component: Collection
      },
      {
        path: '/collection/:id',
        name: 'collection',
        component: CollectionEdit
      },
      {
        path: '/collection/delete/:id',
        name: 'collection-delete',
        component: CollectionDelete
      },
      {
        path: '/faqs',
        name: 'faqs',
        component: Faqs
      },
      {
        path: '/faq/register',
        name: 'faq-register',
        component: FaqRegister
      },
      {
        path: '/faq/delete/:id',
        name: 'faq-delete',
        component: FaqDelete
      },
      {
        path: '/faq/:id',
        name: 'faq-edit',
        component: FaqEdit
      },
      {
        path: '/category-faq',
        name: 'category-faq',
        component: FaqCategory
      },
      {
        path: '/colors',
        name: 'colors',
        component: Colors
      },
      {
        path: '/color/register',
        name: 'color-register',
        component: ColorRegister
      },
      {
        path: '/color/delete/:id',
        name: 'color-delete',
        component: ColorDelete
      },
      {
        path: '/color/:id',
        name: 'color-edit',
        component: ColorEdit
      },
      {
        path: '/hide-panels',
        name: 'hide-panels',
        component: HidePanels
      },
      {
        path: '/hide-panel/register',
        name: 'hide-panel-register',
        component: HidePanelRegister
      },
      {
        path: '/hide-panel/delete/:id',
        name: 'hide-panel-delete',
        component: HidePanelDelete
      },
      {
        path: '/hide-panel/:id',
        name: 'hide-panel-edit',
        component: HidePanelEdit
      }
    ]
  },
  {
    path: '/nav',
    name: 'navigation',
    component: Navigation,
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['auth/isAuth']) {
      next();
    } else {
      next({ path: '/auth' });
    }
  } else {
    next();
  }
});

export default router;
