<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {}
};
</script>

<style lang="scss">
@import 'design';

#app {
  @extend %font-content;
}

h1 {
  font-size: 30px;
  line-height: 42px;
  letter-spacing: 2px;
  color: var(--gray);
}

h2 {
  font-size: 16px;
}
</style>
