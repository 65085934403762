const track = {
  value: 'track',
  lang: {
    ru: 'цвет скрытия / Track',
    en: 'Mechanism hiding color / Track'
  }
};
const rail = {
  value: 'rail',
  lang: {
    ru: 'цвет профиля',
    en: 'Profile color'
  }
};
const chain = {
  value: 'chain',
  lang: {
    ru: 'цвет цепочки',
    en: 'chain color'
  }
};
const fixed = {
  value: 'fixed',
  lang: {
    ru: 'цвет крепления',
    en: 'Fixing details color'
  }
};

export const allTypes = [track, rail, chain, fixed];
export default {
  1: [track, rail, chain, fixed], // rollo
  2: [rail, fixed], // flow
  3: [track, rail, fixed], // panel
  4: [rail], // banner
  5: [track] // slides
};
