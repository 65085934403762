import cloneDeep from 'lodash-es/cloneDeep';
import { api } from '@/api';

export default {
  namespaced: true,
  state: {
    coefficients: [],
    showModal: false,
    error: false,
    commit: ''
  },
  mutations: {
    createCoefficient(state) {
      state.showModal = !state.showModal;
    },
    saveDataState(state, rateData) {
      state.coefficients.push(rateData);
    },
    getDataState(state, rateData) {
      state.coefficients = rateData.coefficients;
      state.error = false;
    },
    error(state) {
      state.error = true;
    },
    editValue(state, id) {
      state.coefficients[id].edit = !state.coefficients[id].edit;
    },
    messageSuccess(state, msg) {
      state.commit = msg;
      setTimeout(() => {
        state.commit = '';
      }, 3000);
    },
    itemChange(state, { value, index }) {
      state.coefficients[index].value = value;
    },
    itemDelete(state, item) {
      const copyState = cloneDeep(state.coefficients);
      const index = copyState.findIndex((el) => el.id === item.id);
      if (index > -1) copyState.splice(index, 1);
      state.coefficients = copyState;
    }
  },
  actions: {
    setValue({ commit }, id) {
      commit('editValue', id);
    },
    postData({ commit }, userData) {
      api
        .post(
          '/coefficients/',
          JSON.stringify({
            model: userData.model,
            collection: userData.collection,
            position: userData.position,
            value: userData.value,
            title: userData.title
          })
        )
        .then((res) => {
          commit('saveDataState', {
            id: res.id,
            model: res.model,
            collection: res.collection,
            value: res.value,
            title: res.title
          });
          // commit('editValue', index);
        })
        .catch((error) => {
          commit('error');
          console.log(error, 'error');
        });
    },
    putData({ commit }, { id, value, index }) {
      commit('itemChange', {
        value,
        index
      });
      api
        .put(
          `/coefficients/${id}`,
          JSON.stringify({
            value
          })
        )
        .then((res) => {
          commit('itemChange', {
            value: res.value,
            index
          });
          commit('messageSuccess', 'Успешно сохранено');
          // commit('editValue', index);
        })
        .catch((error) => {
          commit('error');
          console.log(error, 'error');
        });
    },
    deleteData({ commit }, { id, item }) {
      api
        .delete(`/coefficients/${id}`)
        .then(() => {
          commit('itemDelete', item);
        })
        .catch((error) => {
          commit('error');
          console.log(error, 'error');
        });
    },
    /*    postData({ commit }, { path, value }) {
      commit('postData', { path, value });
    }, */
    getData({ commit }, userData) {
      api
        .get(
          `/coefficients/?model=${userData.model}&collection=${userData.collection.toLowerCase()}`
        )
        .then((res) => {
          commit('getDataState', {
            coefficients: res
          });
        })
        .catch((error) => {
          console.log(error, 'error');
          commit('error');
        });
    }
  }
};
