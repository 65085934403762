<template>
  <div class="faq">
    <form class="faq__form" @submit.prevent="registerFaq">
      <div class="faq__line">
        <drop-down-select
          :width="390"
          :name="$t('faqs.category')"
          :options="categories"
          :value="type"
          @change="changeInput('type', $event)"
        >
          <template v-slot:default="props">
            <div>{{ props.option.name }}</div>
          </template>
        </drop-down-select>
        <router-link class="faq__category-add" to="/category-faq">
          <button class="button-submit" type="button">+ {{ $t('faqs.add_category') }}</button>
        </router-link>
      </div>
      <div class="faq__col">
        <texrarea-input
          ref="question"
          :name="$t('faqs.questionName')"
          :placeholder="$t('faqs.question')"
          :value="question"
          @change="changeInput('question', $event)"
        />
        <texrarea-input
          ref="answer"
          :name="$t('faqs.answerName')"
          :placeholder="$t('faqs.answer')"
          :value="answer"
          @change="changeInput('answer', $event)"
        />
      </div>
      <div class="button-container">
        <button class="button-submit" type="submit">{{ $t('lk.save') }}</button>
      </div>
    </form>
    <div class="message">{{ message }}</div>
  </div>
</template>

<script>
import { api } from '@/api';
import { errorHandler } from '@/mixins';
import DropDownSelect from '../../inputs/drop-down-select/DropDownSelect.vue';
import TexrareaInput from '../../inputs/textarea/TexrareaInput.vue';

export default {
  name: 'FaqRegister',
  components: { DropDownSelect, TexrareaInput },
  mixins: [errorHandler],
  data() {
    return {
      question: '',
      answer: '',
      type: '',
      categories: [],
      message: ''
    };
  },
  mounted() {
    api.get('/category-faq').then((res) => {
      if (res.error) {
        this.message = `Error ${res.error}`;
        return;
      }
      this.categories = res.result;
    });
  },
  methods: {
    changeInput(key, value) {
      this[key] = value;
      this.message = '';
    },
    registerFaq() {
      const data = {
        question: this.question,
        answer: this.answer,
        type: this.type
      };
      console.log('data', data);

      api
        .post('/faqs', JSON.stringify(data))
        .then((res) => {
          if (res.message) return this.messageHandler(`Error ${res.message}`);
          window.setTimeout(this.redirectToFaqs, 1000);
          return this.messageHandler(this.$t('faqs.saveded_successfully'));
        })
        .catch((error) => {
          this.messageHandler(`Error ${error}`);
        });
    },
    redirectToFaqs() {
      window.location.href = `${window.location.origin}/faqs`;
    }
  }
};
</script>

<style lang="scss">
.button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.faq {
  .message {
    padding-top: 20px;
  }

  &__form {
  }

  &__category-add {
    margin-bottom: 20px;
  }

  &__line {
    display: flex;
  }
  &__col {
    display: flex;
    flex-direction: column;
    max-width: 700px;
  }
}
</style>
