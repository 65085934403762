<template>
  <div class="profile">
    <form class="profile__form" @submit.prevent="sendUser">
      <div class="profile__line">
        <div v-if="this.auth.role === 'admin'">
          <text-input
            :width="200"
            :name="$t('profile.agent')"
            :placeholder="$t('profile.agent')"
            :value="agent"
            @change="changeInput('agent', $event)"
          />
        </div>
        <text-input
          :width="200"
          :name="$t('profile.name')"
          :placeholder="$t('profile.name')"
          :value="fname"
          @change="changeInput('fname', $event)"
        />
        <text-input
          :width="200"
          :name="$t('profile.surname')"
          :placeholder="$t('profile.surname')"
          :value="sname"
          @change="changeInput('sname', $event)"
        />
      </div>
      <div class="profile__line">
        <custom-masked-input
          v-model="wphone"
          :width="200"
          :name="$t('profile.work_phone')"
          :placeholder="$t('profile.work_phone')"
          type="tel"
          mask="\+\ 1 111 111 11 11"
        />
        <custom-masked-input
          v-model="mphone"
          :width="200"
          :name="$t('profile.mobile_phone')"
          :placeholder="$t('profile.mobile_phone')"
          type="tel"
          mask="\+\ 1 111 111 11 11"
        />
        <div v-if="this.auth.role === 'admin'">
          <number-input
            :width="200"
            :min="0"
            :max="50"
            :name="$t('profile.sale')"
            :placeholder="$t('profile.sale')"
            :value="maxdiscount"
            @change="changeInput('maxdiscount', $event)"
          />
        </div>
      </div>
      <div class="profile__line">
        <drop-down-select
          :width="420"
          :name="$t('distributor.vat.title')"
          :value="vat_value"
          :options="vat_list"
          @change="viewFiltersVAT('vat_value', $event)"
        >
          <template v-slot:default="props">
            <div>{{ props.option.name }}</div>
          </template>
        </drop-down-select>
      </div>
      <div v-if="register" class="profile__line">
        <text-input
          :width="200"
          name="E-mail"
          placeholder="E-mail"
          :value="email"
          @change="changeInput('email', $event)"
        />
        <text-input
          :width="200"
          :name="$t('profile.password')"
          :placeholder="$t('profile.password')"
          :value="password"
          @change="changeInput('password', $event)"
        />
      </div>
      <div v-if="auth.role === 'admin'" class="profile__line">
        <custom-checkbox v-model="configurator" :title="$t('profile.configurator')" />
        <custom-checkbox v-model="library" :title="$t('profile.library')" />
        <custom-checkbox v-model="services" :title="$t('profile.certificates')" />
      </div>
      <div class="button-container">
        <button :disabled="checkRegister" class="button-submit" type="submit">
          {{ $t('lk.save') }}
        </button>
        <button type="button" class="button-submit" @click="changePassword = true">
          {{ $t('profile.change_password') }}
        </button>
      </div>
    </form>
    <div class="message">{{ message }}</div>
    <the-modal
      v-if="changePassword"
      :title="$t('profile.change_password')"
      @close="changePassword = false"
    >
      <form @submit.prevent="sendChangePassword">
        <text-input
          :width="230"
          type="password"
          :name="$t('profile.old_password')"
          :placeholder="$t('profile.necessarily')"
          :value="currentPassword"
          @change="changeInput('currentPassword', $event)"
        />
        <text-input
          :width="230"
          type="password"
          :name="$t('profile.new_password')"
          :placeholder="$t('profile.necessarily')"
          :value="newPassword"
          @change="changeInput('newPassword', $event)"
        />
        <text-input
          :width="230"
          type="password"
          :name="$t('profile.repeat_password')"
          :placeholder="$t('profile.necessarily')"
          :value="newPasswordRepeat"
          @change="changeInput('newPasswordRepeat', $event)"
        />
        <button type="submit" class="button-submit">{{ $t('lk.save_changes') }}</button>
      </form>
      <div class="message">{{ messagePassword }}</div>
    </the-modal>
  </div>
</template>

<script>
import TextInput from '@/components/inputs/text-input/TextInput';
import { mapState } from 'vuex';
import { api } from '@/api';
import NumberInput from '@/components/inputs/number-input/NumberInput';
import CustomMaskedInput from '@/components/inputs/masked-input/CustomMaskedInput';
import CustomCheckbox from '@/components/inputs/custom-checkbox/CustomCheckbox';
import DropDownSelect from '@/components/inputs/drop-down-select/DropDownSelect';
import { errorHandler } from '@/mixins';
import TheModal from '@/components/ui/TheModal';
import { dutchedVersion } from '@/config';
import countries from '@/dictionary/countries';

export default {
  name: 'User',
  components: {
    TheModal,
    CustomCheckbox,
    CustomMaskedInput,
    DropDownSelect,
    NumberInput,
    TextInput
  },
  mixins: [errorHandler],
  computed: {
    ...mapState(['auth', 'user']),
    checkRegister() {
      return this.register ? !this.email || !this.password : false;
    }
  },
  watch: {
    changePassword: {
      handler(newVal) {
        if (!newVal) {
          this.newPassword = '';
          this.newPasswordRepeat = '';
          this.currentPassword = '';
          this.messagePassword = '';
        }
      },
      deep: true
    }
  },
  data() {
    return {
      message: '',
      messagePassword: '',
      agent: '',
      fname: '',
      sname: '',
      email: '',
      password: '',
      wphone: '',
      mphone: '',
      maxdiscount: 0,
      acctype: 'manager',
      configurator: false,
      library: false,
      services: false,
      register: false,
      changePassword: false,
      currentPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
      extendedData: {},
      vat_value: '',
      vat_country: '',
      vat_percent: '',
      vat_list: [],
      countries: []
    };
  },
  mounted() {
    api.setBearer(localStorage.getItem('token'));
    this.prepareCountries();
    if (this.$route.params.id.includes('register')) {
      this.register = true;
    } else {
      const userId = this.$route.params.id || this.auth.id;
      this.register = false;
      api
        .get(`/users/${userId}`)
        .then((res) => {
          // console.log('res', res);
          this.agent = res.agent;
          this.fname = res.fname;
          this.sname = res.sname;
          this.email = res.email;
          this.wphone = res.wphone;
          this.mphone = res.mphone;
          this.maxdiscount = res.maxdiscount;
          this.acctype = res.acctype;
          this.configurator = res.configurator;
          this.library = res.library;
          this.services = res.services;
          if (res.extendedData) this.extendedData = res.extendedData;
          if (res.extendedData && res.extendedData.vat_country && res.extendedData.vat_value) {
            this.vat_value = `${res.extendedData.vat_country.toLowerCase()}_${
              res.extendedData.vat_value
            }`;
          }
        })
        .catch((error) => {
          console.log(error);
          this.messageHandler(`Ошибка ${error}`);
        });
    }
  },
  methods: {
    sendChangePassword() {
      if (this.newPassword !== this.newPasswordRepeat) {
        this.messagePassword = 'Новые пароли не совпадают';
        if (dutchedVersion) this.messagePassword = 'Password mismatch';
        return;
      }

      if (!this.newPassword || !this.newPasswordRepeat || !this.currentPassword) {
        this.messagePassword = 'Заполните все поля';
        if (dutchedVersion) this.messagePassword = 'Fill in all the fields';
        return;
      }

      api
        .post(
          '/users/change-password',
          JSON.stringify({
            oldPassword: this.currentPassword,
            newPassword: this.newPassword
          })
        )
        .then((res) => {
          if (res.statusCode) throw new Error('Пароль не сменен. Попробуйте снова');
          this.changePassword = false;
          if (dutchedVersion) return this.messageHandler('Password changed successfully');
          return this.messageHandler('Пароль успешно сменен');
        })
        .catch((e) => {
          this.messagePassword = e;
        });
    },
    changeInput(key, value) {
      this[key] = value;
      this.message = '';
      this.messagePassword = '';
    },
    viewFiltersVAT(key, value) {
      this[key] = value;
      const splitedValue = value.split('_');
      [this.vat_country, this.vat_percent] = splitedValue;
      this.extendedData.vat_country = this.vat_country.toUpperCase();
      this.extendedData.vat_value = this.vat_percent;
    },
    changeAccess(userId) {
      api.post(
        `/users/access/${userId}`,
        JSON.stringify({
          configurator: this.configurator,
          library: this.library,
          services: this.services
        })
      );
    },
    prepareCountries() {
      countries.forEach((countryItem) => {
        // console.log('countryItem', countryItem);
        const localizedCountryName = this.$t(`countries.${countryItem.code}`);
        this.vat_list.push({
          value: `${countryItem.code}_${countryItem.vat}`,
          name: `${localizedCountryName} ${countryItem.vat}%`
        });
      });
    },
    registerUser() {
      api
        .post(
          '/users',
          JSON.stringify({
            agent: this.agent,
            fname: this.fname,
            sname: this.sname,
            email: this.email,
            password: this.password,
            wphone: this.wphone,
            mphone: this.mphone,
            maxdiscount: this.maxdiscount,
            extendedData: this.extendedData
          })
        )
        .then((res) => {
          if (res.message) return this.messageHandler(`Error ${res.message}`);
          this.changeAccess(res.id);
          if (dutchedVersion) return this.messageHandler('User create');
          return this.messageHandler('Пользователь создан');
        })
        .catch((error) => {
          this.messageHandler(`Error ${error}`);
        });
    },
    sendUser() {
      if (this.register) {
        this.registerUser();
        return;
      }
      const userId = this.$route.params.id || this.auth.id;
      api
        .put(
          `/users/${userId}`,
          JSON.stringify({
            agent: this.agent,
            fname: this.fname,
            sname: this.sname,
            email: this.email,
            wphone: this.wphone,
            mphone: this.mphone,
            maxdiscount: this.maxdiscount,
            extendedData: this.extendedData
          })
        )
        .then((res) => {
          if (res.message) return this.messageHandler(`Ошибка ${res.message}`);
          this.changeAccess(userId);
          if (dutchedVersion) return this.messageHandler('Successfully saved');
          return this.messageHandler('Данные успешно сохранены');
        })
        .catch((error) => {
          this.messageHandler(`Error ${error}`);
        });
    }
  }
};
</script>

<style lang="scss">
.button-container {
  display: flex;
  justify-content: space-between;
}

.profile {
  &__change-password {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .message {
    padding-top: 20px;
  }

  &__form {
  }

  &__line {
    display: flex;
  }
}
</style>
