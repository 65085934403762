<template>
  <main-layout />
</template>

<script>
import MainLayout from '@/components/ui/MainLayout';

export default {
  name: 'Home',
  components: { MainLayout }
};
</script>

<style lang="scss"></style>
