<template>
  <div class="faq">
    <form class="faq__form" @submit.prevent="updateFaq">
      <div class="faq__line">
        <drop-down-select
          :width="390"
          :name="$t('faqs.category')"
          :value="model.type"
          :options="categories"
          @change="changeInput('type', $event)"
        >
          <template v-slot:default="props">
            <div>{{ props.option.name }}</div>
          </template>
        </drop-down-select>
      </div>
      <div class="faq__col">
        <texrarea-input
          ref="question"
          :name="$t('faqs.questionName')"
          :placeholder="$t('faqs.question')"
          :value="model.question"
          @change="changeInput('question', $event)"
        />
        <texrarea-input
          ref="answer"
          :name="$t('faqs.answerName')"
          :placeholder="$t('faqs.answer')"
          :value="model.answer"
          @change="changeInput('answer', $event)"
        />
      </div>
      <div class="button-container">
        <button class="button-submit" type="submit">{{ $t('lk.save') }}</button>
      </div>
    </form>
    <div class="message">{{ message }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { api } from '@/api';
import DropDownSelect from '@/components/inputs/drop-down-select/DropDownSelect';
import { errorHandler } from '@/mixins';
import TexrareaInput from '../../inputs/textarea/TexrareaInput.vue';

export default {
  name: 'Faq',
  components: {
    DropDownSelect,
    TexrareaInput
  },
  mixins: [errorHandler],
  computed: {
    ...mapState(['auth', 'user'])
  },
  watch: {},
  data() {
    return {
      faq_id: this.$route.params.id,
      model: {},
      message: '',
      categories: []
    };
  },
  mounted() {
    api.get(`/faqs/${this.faq_id}`).then((res) => {
      if (res.error) {
        this.message = `Error ${res.error}`;
        return;
      }
      this.model = res.result;
    });
    api.get('/category-faq').then((res) => {
      if (res.error) {
        this.message = `Error ${res.error}`;
        return;
      }
      this.categories = res.result;
    });
  },
  methods: {
    changeInput(key, value) {
      this.model[key] = value;
    },
    updateFaq() {
      api
        .patch(`/faqs/${this.faq_id}`, JSON.stringify(this.model))
        .then((res) => {
          if (res.message) return this.messageHandler(`Error ${res.message}`);
          window.setTimeout(this.redirectToFaqs, 1000);
          return this.messageHandler(this.$t('faqs.saveded_successfully'));
        })
        .catch((error) => {
          this.messageHandler(`Error ${error}`);
        });
    },
    redirectToFaqs() {
      window.location.href = `${window.location.origin}/faqs`;
    }
  }
};
</script>

<style lang="scss">
.button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.delete-material-btn {
  border: 0;
}

.delete-material-btn:hover {
  cursor: pointer;
}
</style>
