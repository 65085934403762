<template>
  <div class="users-container">
    <table class="users">
      <tr v-for="(item, index) in users" :key="index">
        <td class="users__data">
          <h2>{{ checkFullUser(item) }}</h2>
          <span class="users__info">
            {{ item.acctype }} {{ item.mphone ? '/ ' + item.mphone : '' }}
          </span>
          <span v-if="item.enterprise" class="users__info">distributor</span>
        </td>
        <td>
          <router-link v-if="item.enterprise" :to="`/distributor/${item.id}`">
            <img src="../../../assets/edit.svg" />
          </router-link>
          <router-link v-if="!item.enterprise" :to="`/user/${item.id}`">
            <img src="../../../assets/edit.svg" />
          </router-link>
        </td>
        <td>
          <router-link :to="`/user/delete/${item.id}`">
            <img src="../../../assets/close.svg" />
          </router-link>
        </td>
      </tr>
    </table>

    <router-link class="distributor-add" to="/distributor/register">
      <button class="button-submit register-btn" type="submit">+{{ $t('distributor.add') }}</button>
    </router-link>

    <router-link class="user-add" to="/user/register">
      <button class="button-submit register-btn" type="submit">+{{ $t('users.add') }}</button>
    </router-link>
  </div>
</template>

<script>
import { api } from '@/api';

export default {
  name: 'Users',
  data() {
    return {
      users: []
    };
  },
  mounted() {
    api.setBearer(localStorage.getItem('token'));
    api
      .get('/users')
      .then((res) => {
        this.users = res;
      })
      .catch((error) => {
        this.message = `Error ${error}`;
      });
  },
  methods: {
    checkFullUser(item) {
      if (!item.sname && !item.fname) return 'NoName';
      return `${item.sname} ${item.fname}`;
    }
  }
};
</script>

<style lang="scss">
.users {
  width: 100%;

  &-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__data {
    display: flex;
    align-items: center;
  }

  &__info {
    color: var(--gray);
    font-size: 12px;
    margin-left: 10px;
  }
}

.register-btn {
  margin-top: 20px;
}
</style>
