<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.5"
      y="0.5"
      width="19"
      height="19"
      rx="3.5"
      fill="#AC864F"
      fill-opacity="0.16"
      stroke="#DABE96"
    />
    <path
      d="M8.31479 13.8585L5.13296 10.4413C4.95568 10.2533 4.95568 9.94725 5.13296 9.75723L5.77569 9.07419C5.95296 8.88618 6.24114 8.88618 6.41842 9.07419L8.63661 11.4663L13.5812 6.14101C13.7585 5.953 14.0466 5.953 14.2239 6.14101L14.8666 6.82505C15.0439 7.01306 15.0439 7.32008 14.8666 7.50709L8.95752 13.8585C8.78025 14.0465 8.49206 14.0465 8.31479 13.8585Z"
      :fill="checked ? '#DABE96' : 'none'"
    />
  </svg>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      required: true,
      default: false
    }
  }
};
</script>

<style lang="scss"></style>
