export default [
  {
    id: 1,
    value: 'rollo',
    name: 'Rollo',
    img: 'resources/rollo.jpg'
  },
  {
    id: 3,
    value: 'panel',
    name: 'Panel',
    img: 'resources/panels.jpg'
  },
  {
    id: 4,
    value: 'banner',
    name: 'Banner',
    img: 'resources/banners.jpg'
  },
  {
    id: 2,
    value: 'flow',
    name: 'Flow',
    img: 'resources/flow.jpg'
  },
  {
    id: 5,
    value: 'slides',
    name: 'Slides',
    img: 'resources/slides.jpg'
  }
];
