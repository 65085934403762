export const errorHandler = {
  methods: {
    messageHandler(msg) {
      this.message = msg;
      setTimeout(() => {
        this.message = '';
      }, 3000);
    }
  }
};

export const test = {};

export const modelNumberWithIndividualSectionHeight = {
  data() {
    return {
      modelNumberWithIndividualSectionHeight: [1, 3, 4] // rollo, panel, banner
    };
  }
};
