<template>
  <div class="collection-modal">
    <div class="collection-modal__wrap">
      <h2 class="collection-modal__header">
        <slot name="header"></slot>
      </h2>
      <slot name="body"></slot>
      <div class="collection-modal__footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.collection-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  min-height: 100%;
  background-color: var(--white);
  // .collection-modal__wrap
  &__wrap {
    max-width: 240px;
    padding: 50px;
  }
  // .collection-modal__header
  &__header {
    margin: 0px 0px 30px 0px;
    font-size: 24px;
    font-weight: 500;
    color: var(--dark-gray);

    &::first-letter {
      text-transform: uppercase;
    }
  }
  // .collection-modal__footer
  &__footer {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
  }
}
</style>
