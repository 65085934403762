import { api } from '@/api';

export default {
  namespaced: true,
  state: {
    id: null,
    token: null,
    role: null,
    commit: ''
  },
  getters: {
    isAuth: ({ token }) => !!token
  },
  mutations: {
    login(state, userData) {
      state.token = userData.token;
      state.id = userData.id;
      state.role = userData.role;
      localStorage.setItem('token', userData.token);
    },
    logout(state) {
      state.token = null;
      state.id = null;
      state.role = null;
      localStorage.removeItem('token');
    },
    messageSuccess(state, msg) {
      state.commit = msg;
      setTimeout(() => {
        state.commit = '';
      }, 3000);
    }
  },
  actions: {
    login({ commit }, authData) {
      api
        .post(
          '/auth/login',
          JSON.stringify({
            username: authData.username,
            password: authData.password
          })
        )
        .then((res) => {
          commit('login', {
            token: res.access_token,
            id: res.userId,
            role: res.role
          });
          if (res.statusCode) {
            throw new Error('Неправильный логин или пароль');
          }
        })
        .catch((error) => {
          commit('messageSuccess', error);
        });
    },
    logout({ commit }) {
      commit('logout');
    }
  }
};
