<template>
  <div class="drop-down-select" :style="{ width: width + 'px' }">
    <span class="drop-down-select__label">{{ name }}</span>
    <div class="drop-down-select__input" @click="toggle()">
      <div v-if="content">
        <slot :option="content"></slot>
      </div>
      <div v-else>-</div>
    </div>
    <div class="drop-down-select__options" :class="{ opened: opened }">
      <div
        v-if="empty"
        :key="empty"
        class="drop-down-select__option"
        @click="handleChange(emptyOption)"
      >
        <slot :option="emptyOption"></slot>
      </div>
      <div
        v-for="(option, index) in options"
        :key="index"
        class="drop-down-select__option"
        :class="[content === option ? 'active' : '']"
        @click="handleChange(option)"
      >
        <slot :option="option"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropDownSelect',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    name: String,
    width: {
      type: Number
    },
    empty: {
      type: Boolean,
      default: false
    },
    value: [String, Number, Boolean, Object, Date, Symbol, Function],
    options: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      emptyOption: {
        value: null,
        name: '-'
      },
      opened: false
    };
  },
  computed: {
    content() {
      return this.options.find((option) => option.value === this.value);
    }
  },
  created() {
    document.addEventListener('click', this.clickOutside);
    this.$on('hook:beforeDestroy', () => document.removeEventListener('click', this.clickOutside));
  },
  methods: {
    toggle() {
      this.opened = !this.opened;
    },
    clickOutside(e) {
      if (!this.$el.contains(e.target)) {
        this.opened = false;
      }
    },
    handleChange(option) {
      this.opened = false;
      this.$emit('change', option.value);
    }
  }
};
</script>

<style lang="scss">
.drop-down-select {
  display: flex;
  flex-direction: column;
  min-width: 100px;
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  // width: max-content;
  // padding: 0 10px;

  &__label {
    position: absolute;
    // position: relative;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--gray);
    background: var(--white);
    font-size: 10px;
    padding: 0 7px;
    text-transform: lowercase;
    white-space: pre;
    // margin: 0 auto;
  }

  &__input {
    height: 36px;
    line-height: 36px;
    // padding: 0 25px;
    border: 1px solid var(--light-gray);
    background-color: var(--dark);
    text-align: center;
    display: flex;
    justify-content: center;

    &:hover,
    &:active,
    &:focus {
      border: 1px solid var(--gold);
    }
  }

  &__options {
    background: var(--white);
    position: absolute;
    top: 36px;
    left: 0;
    right: 0;
    z-index: 3;
    display: none;
    margin-bottom: 15px;
    &.opened {
      display: block;
      box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
      overflow-y: auto;
      max-height: 300px;
      text-align: center;
      // white-space: nowrap;
      // word-break: break-all;
      // min-width: max-content;
    }
  }

  &__option {
    // padding-left: 15px;
    background-color: var(--dark);
    margin: 1px 0;
    /*    height: 36px;
    line-height: 36px;*/
    padding: 5px;
    &:hover {
      color: var(--gold);
    }
    &.active {
      font-weight: bold;
      color: var(--gold);
    }
  }
}

.option {
  width: 100%;
  display: flex;
  justify-content: center;

  &__color {
    width: 56px;
  }

  &__icon {
    width: 36px;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.color-option {
  display: flex;
  justify-content: space-between;
  &__color {
    width: 56px;
  }
}
</style>
