<template>
  <div class="select" :class="{ opened: show }">
    <h2 class="select__title" @click="toggleView(!show)">
      <span>{{ name }}</span>
    </h2>
    <form
      v-show="show"
      class="select__form"
      v-bind="$attrs"
      :value="value"
      @change="onChange($event)"
    >
      <label v-for="option of options" :key="option.value" class="select__field">
        <span>{{ option.name }}</span>
        <input type="checkbox" :checked="hasChecked(option.id)" :value="option.id" />
        <icon-arrow :checked="hasChecked(option.id)" />
      </label>
    </form>
  </div>
</template>

<script>
import IconArrow from '@/components/ui/icons/IconArrow.vue';
export default {
  components: { IconArrow },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: '',
      required: true
    },
    options: {
      type: Array,
      default: () => [],
      required: true
    },
    value: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data() {
    return {
      newValue: [],
      show: false
    };
  },
  mounted() {
    this.newValue = this.value;
  },
  methods: {
    onChange(event) {
      const item = this.options.find((opt) => String(opt.id) === event.target.value);

      const contains = this.newValue.find((val) => val.id === item.id);
      if (contains) {
        this.newValue = this.newValue.filter((val) => val.id !== item.id);
      } else {
        this.newValue.push(item);
      }
      this.$emit('change', this.newValue);
    },
    toggleView(value) {
      this.show = value;
    },
    hasChecked(id) {
      const checked = this.newValue.find((val) => val.id === id);
      return Boolean(checked);
    }
  }
};
</script>

<style lang="scss">
.select {
  text-align: center;
  background-color: var(--dark);

  &.opened {
    .select__form {
      box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
      overflow-y: auto;
      max-height: 300px;
      text-align: center;
    }
  }

  // .select__title
  &__title {
    position: relative;
    margin: 0;
    font-weight: 500;
    line-height: 36px;
    font-size: 10px;
    user-select: none;
    cursor: pointer;
    span {
      display: inline-block;
      padding: 0 10px;
      color: var(--gray);
      transform: translateY(-50%);
      background-color: var(--white);
      text-transform: lowercase;
    }

    &::after {
      content: '-';
      position: absolute;
      top: 0;
      left: 50%;
    }

    border: 1px solid var(--light-gray);
    &:hover,
    &:active,
    &:focus {
      border: 1px solid var(--gold);
    }
  }
  // .select__form
  &__form {
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    padding: 8px 0;
  }
  // .select__field
  &__field {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 25px;
    padding: 0 15px;
    transition: all 0.3s linear;
    user-select: none;
    cursor: pointer;

    &:hover {
      span {
        color: var(--gold);
      }
    }

    span {
      font-size: 12px;
      line-height: 140%;
      font-weight: 500;
      color: var(--dark-gray);

      &::first-letter {
        text-transform: uppercase;
      }
    }

    input {
      appearance: none;
      pointer-events: none;
    }
  }
}
</style>
