import { render, staticRenderFns } from "./CustomCheckbox.vue?vue&type=template&id=6b540922&"
import script from "./CustomCheckbox.vue?vue&type=script&lang=js&"
export * from "./CustomCheckbox.vue?vue&type=script&lang=js&"
import style0 from "./CustomCheckbox.vue?vue&type=style&index=0&id=6b540922&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports