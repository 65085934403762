<template>
  <div class="delete-hide-panel">
    <h1>{{ $t('delete_confirm') }}</h1>
    <div class="button-container">
      <button class="button-submit delete-btn" type="submit" @click="deleteHidePanel">
        {{ $t('delete') }}
      </button>

      <router-link class="hide-panel-add" to="/hide-panels">
        <button class="button-submit delete-btn" type="submit">
          {{ $t('cancel') }}
        </button>
      </router-link>
    </div>
    <div class="error">{{ error_message }}</div>
    <div class="success">{{ success_message }}</div>
  </div>
</template>

<script>
import { api } from '@/api';
import { errorHandler } from '@/mixins';

export default {
  name: 'HidePanelDelete',
  components: {},
  mixins: [errorHandler],
  data() {
    return {
      hidePanelId: this.$route.params.id,
      error_message: '',
      success_message: ''
    };
  },
  methods: {
    deleteHidePanel() {
      api
        .delete(`/hide-panels/${this.hidePanelId}`)
        .then((res) => {
          if (!res || res.error || res.message) {
            this.error_message = 'Failed to delete hide-panel';
            return;
          }
          this.success_message = 'hide-panel deleted successfully';
          window.setTimeout(this.redirectToHidePanels, 1000);
        })
        .catch((error) => {
          console.error('error', error);
          this.error_message = 'Failed to delete collection';
        });
    },
    redirectToHidePanels() {
      window.location.href = `${window.location.origin}/hide-panels`;
    }
  }
};
</script>

<style lang="scss">
.button-container {
  display: flex;
  justify-content: space-between;
}

.delete-btn:hover {
  cursor: pointer;
}

.delete-hide-panel {
  &__line {
    display: flex;
  }

  .error {
    margin-top: 20px;
    color: red;
  }

  .success {
    margin-top: 20px;
    color: green;
  }
}
</style>
