<template>
  <div class="overlay">
    <div class="modal">
      <div class="modal__confirm">
        <div class="modal__title">
          <h5>{{ title }}</h5>
          <span @click="closeEditFolder"><img src="../../assets/close.svg" alt="close" /></span>
        </div>
        <div class="modal__body">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheModal',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    closeEditFolder() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss">
.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
}

.modal {
  position: relative;
  top: 25%;

  &__confirm {
    color: #191919;
    width: 400px;
    top: 20%;
    margin: 0 auto;
    z-index: 10;
    position: relative;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background-color: #444444;
    color: #ffffff;
  }

  &__body {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 20px;
  }

  &__content {
    margin-bottom: 10px;
  }
}
</style>
