<template>
  <div class="auth-container">
    <div class="auth-form">
      <div class="auth-form__confirm">
        <form class="auth-form__body" @submit.prevent="login">
          <img alt="logo" src="../../assets/logo.svg" />
          <br />
          <h3 class="auth-form__title">Authorization</h3>
          <br />
          <div class="auth-form__input-container">
            <text-input
              id="login"
              class="auth-form__input"
              :width="250"
              :value="username"
              type="login"
              name="email"
              @change="changeEmail($event)"
            />
          </div>
          <br />
          <div class="auth-form__input-container">
            <text-input
              id="password"
              class="auth-form__input"
              :width="250"
              :value="password"
              type="password"
              name="password"
              @change="changePassword($event)"
            />
          </div>
          <br />
          <div class="auth-form__additional">
            <div class="auth-form__recover-passw">Forgot password</div>
            <button class="button-submit auth-form__submit" type="submit">Submit</button>
          </div>
          <div class="error">{{ auth.commit }}</div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TextInput from '@/components/inputs/text-input/TextInput';
import { api } from '@/api';
import { CONSTRUCTOR_URL, LIBRARY_URL } from '@/config';

export default {
  name: 'Auth',
  components: {
    TextInput
  },
  data() {
    return {
      username: '',
      password: '',
      fullPage: true
    };
  },
  computed: {
    ...mapState(['auth'])
  },
  watch: {
    'auth.token': {
      handler: function userRedirectHandler() {
        this.userRedirect();
      },
      deep: true
    }
  },
  created() {
    this.$store.commit('auth/messageSuccess', '');
    this.CONSTRUCTOR_URL = CONSTRUCTOR_URL;
    this.LIBRARY_URL = LIBRARY_URL;
  },
  methods: {
    userRedirect() {
      api.setBearer(localStorage.getItem('token'));
      api
        .get(`/users/${this.auth.id}`)
        .then((res) => {
          switch (true) {
            case res.configurator && res.library:
              this.$router.replace('/nav');
              break;
            case res.library:
              window.location.href = `${this.LIBRARY_URL}/?token=${this.auth.token}`;
              break;
            case res.configurator:
              window.location.href = `${this.CONSTRUCTOR_URL}/?token=${this.auth.token}`;
              break;
            default:
              this.$router.replace(`/profile/${this.auth.id}`);
          }
        })
        .catch((error) => {
          console.log(error);
          this.message = `Ошибка ${error}`;
        });
    },
    changeEmail(value) {
      this.username = value;
    },
    changePassword(value) {
      this.password = value;
    },
    login() {
      this.$store.dispatch('auth/login', {
        username: this.username,
        password: this.password
      });
    }
  }
};
</script>

<style lang="scss">
.auth-container {
  height: 100vh;
  display: flex;
  flex-direction: row;
  background-image: url('./../../assets/bg.png');
  background-size: cover;
}

.auth-form {
  margin: 150px auto;
  width: 450px;

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .error {
      margin-top: 20px;
    }
  }
  &__submit {
    width: 100px;
  }

  &__label {
    position: absolute;
    // position: relative;
    top: -10px;
    left: 100px;
    color: gray;
    background: white;
    font-size: 10px;
    padding: 0 10px;
    text-transform: lowercase;
    margin: 0 auto;
  }

  &__confirm {
    background-color: #fff;
    padding: 50px;
  }

  &__title {
    color: #191919;
    padding-bottom: 20px;
  }

  &__input {
    border: none;
    outline: none;
    padding: 10px;
    text-align: center;
    // margin: 10px 0;
  }

  &__additional {
    display: flex;
    align-items: flex-end;
    width: 235px;
    justify-content: space-between;
  }

  &__recover-passw {
    text-decoration: underline;
    font-size: 12px;
  }
}

.button-submit {
  background: #dabe96;
  height: 40px;
  width: max-content;
  padding: 10px;
  border: 0;

  font-size: 14px;

  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #ffffff;
  &:disabled {
    background: var(--light-gray);
  }
}
</style>
