<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 18.2222V9.33333C20 8.86184 19.8314 8.40965 19.5314 8.07625C19.2313 7.74286 18.8243 7.55556 18.4 7.55556H12.9888C12.6916 7.55554 12.4003 7.46356 12.1475 7.28992C11.8947 7.11628 11.6904 6.86783 11.5576 6.57244L10.8424 4.98311C10.7095 4.68759 10.5051 4.43906 10.2522 4.2654C9.99922 4.09175 9.70773 3.99985 9.4104 4H5.6C5.17565 4 4.76869 4.1873 4.46863 4.5207C4.16857 4.8541 4 5.30628 4 5.77778V18.2222C4 18.6937 4.16857 19.1459 4.46863 19.4793C4.76869 19.8127 5.17565 20 5.6 20H18.4C18.8243 20 19.2313 19.8127 19.5314 19.4793C19.8314 19.1459 20 18.6937 20 18.2222Z"
      stroke="#444444"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconCollections'
};
</script>

<style lang="scss"></style>
