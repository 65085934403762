import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth';
import rate from './modules/rate';
import user from './modules/user';

Vue.use(Vuex);

const authData = createPersistedState({
  key: 'auth',
  paths: ['auth']
});

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    rate,
    user
  },
  plugins: [authData]
});
