<template>
  <div class="hide-panels">
    <table v-if="hidePanels.length" class="hide-panels__table">
      <tr v-for="item in hidePanels" :key="item.id">
        <td>
          <h2>{{ item.name }}</h2>
        </td>
        <td>
          <router-link :to="`/hide-panel/${item.id}`">
            <img src="../../../assets/edit.svg" />
          </router-link>
        </td>
        <td>
          <router-link :to="`/hide-panel/delete/${item.id}`">
            <img src="../../../assets/close.svg" />
          </router-link>
        </td>
      </tr>
    </table>
    <div v-else>{{ loadingMessage }}</div>

    <router-link class="hide-panels__add button-submit register-btn" to="/hide-panel/register">
      + {{ $t('hidePanels.add') }}
    </router-link>
  </div>
</template>

<script>
import { api } from '@/api';

export default {
  name: 'HidePanels',
  data() {
    return {
      hidePanels: [],
      loadingMessage: 'Content loading',
      message: ''
    };
  },
  mounted() {
    api.setBearer(localStorage.getItem('token'));
    api
      .get('/hide-panels')
      .then((res) => {
        if (res && res.result.length) {
          this.hidePanels = res.result;
        } else {
          this.loadingMessage = 'Empty data';
        }
      })
      .catch((error) => {
        this.message = `Error ${error}`;
      });
  }
};
</script>

<style lang="scss">
.hide-panels {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;

  &__table {
    width: 100%;
    margin-bottom: 30px;
    tr {
      display: grid;
      column-gap: 40px;
      align-items: center;
      grid-template-columns: 1fr 30px 30px;
    }
  }

  &__add {
    box-sizing: border-box;
    color: var(--white);
  }
}
</style>
