import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { dutchedVersion } from '@/config';

Vue.use(VueI18n);

const messages = {
  ru: {
    delete_confirm: 'Вы уверены, что хотите удалить?',
    delete: 'Удалить',
    cancel: 'Отмена',
    validate_required: 'Необходимо заполнить поле',
    materials: {
      title: 'Материалы',
      add: 'Добавить материал',
      name: 'Название',
      code: 'Код',
      maxWidth: 'Максимальная ширина',
      maxHeight: 'Максимальная высота',
      perforation: 'Перфорация',
      rotate: 'Поворачиваемость',
      saveded_successfully: 'Материал сохранен!',
      deleted_successfully: 'Материал удален!'
    },
    collections: {
      title: 'Коллекции',
      add: 'Добавить коллекцию',
      name: 'Название',
      code: 'Код',
      materials: 'Материалы',
      hidePanels: 'Скрытие',
      perforation: 'Перфорация',
      combining: 'Комбинирование',
      model: 'Модель',
      min_distance_between_slats: 'Минимальное расстояние между рейками',
      individual_section_height: 'Индивидуальная высота секции',
      min_section_height: 'Минимальная высота секции',
      saveded_successfully: 'Коллекция сохранена!',
      deleted_successfully: 'Коллекция удалена!'
    },
    colors: {
      title: 'Цвета',
      add: 'Добавить цвет',
      name: 'Название',
      code: 'Код',
      category: 'Категории отображения'
    },
    hidePanels: {
      title: 'Скрытие',
      add: 'Добавить скрытие',
      name: 'Название',
      code: 'Код'
    },
    faqs: {
      title: 'FAQs',
      add: 'Добавить FAQ',
      question: 'Вопрос',
      answer: 'Ответ',
      questionName: 'Текст вопроса',
      answerName: 'Текст ответа',
      category: 'Категория вопроса',
      saveded_successfully: 'Сохранено успешно',
      deleted_successfully: 'Удалено успешно',
      key: 'Ключ',
      add_category: 'Добавить категорию'
    },
    lk: {
      title: 'Личный кабинет',
      save: 'Сохранить',
      save_changes: 'Сохранить изменения',
      vat: 'НДС'
    },
    profile: {
      title: 'Профиль',
      agent: 'Агент',
      name: 'Имя',
      surname: 'Фамилия',
      work_phone: 'Рабочий телефон',
      mobile_phone: 'Мобильный телефон',
      sale: 'Макс.скидка',
      configurator: 'Конфигуратор',
      library: 'Библиотека',
      certificates: 'Сертификаты',
      password: 'Пароль',
      change_password: 'Сменить пароль',
      old_password: 'Старый пароль',
      new_password: 'Новый пароль',
      repeat_password: 'Повторите пароль',
      necessarily: 'Обязательно'
    },
    coefficients: {
      title: 'Коэффициенты',
      model: 'Модель',
      collection: 'Коллекция',
      formula: 'Формула',
      count_section: 'от кол-ва секций',
      power_cost: 'стоимость блока питания',
      battery_cost: 'стоимость батареи',
      ch5_cost: 'стоимость пульта 5 каналов',
      ch15_cost: 'стоимость пульта 15 каналов'
    },
    currency: {
      title: 'Курс валют'
    },
    users: {
      title: 'Пользователи',
      add: 'Добавить пользователя',
      delete_confirm: 'Вы уверены, что хотите удалить?',
      delete: 'Удалить',
      cancel: 'Отмена'
    },
    registration: {
      title: 'Регистрация'
    },
    edit: {
      title: 'Редактирование'
    },
    distributor: {
      title: 'Дистрибьютор',
      add: 'Добавить дистрибьютора',
      date_mask: 'дд/мм/гггг',
      yes: 'Да',
      no: 'Нет',
      gender: {
        title: 'Пол',
        male: 'Мужской',
        female: 'Женский'
      },
      partner: {
        title: 'Партнер/Контрагент',
        partner_type: 'Вид партнера',
        entity: 'Юр лицо',
        physical_person: 'Физ лицо',
        legal_entity_non_resident: 'Юр лицо не резидент',
        individual_entrepreneur: 'Индивидуальный предприниматель',
        name: 'Наименование',
        name_full: 'Наименование полное',
        name_international: 'Наименование международное',
        inn: 'ИНН',
        kpp: 'КПП',
        kod_po_okpo: 'КодПоОКПО',
        ogrn: 'ОГРН',
        country_of_registration: 'Страна регистрации',
        registration_date: 'Дата регистрации',
        option_sending_e_check: 'Вариант отправки электронного чека',
        do_not_send: 'Не отправлять',
        sms: 'Отправить SMS',
        email: 'Отправить Email'
      },
      physical_person: {
        title: 'Физ лицо',
        fname: 'Имя',
        sname: 'Фамилия',
        patronymic: 'Отчество',
        birthday: 'Дата рождения',
        inn: 'ИНН'
      },
      contact_person: {
        title: 'Контактное лицо',
        fname: 'Имя',
        sname: 'Фамилия',
        patronymic: 'Отчество',
        phone: 'Телефон',
        email: 'Емейл',
        sex: 'Пол',
        position: 'Должность'
      },
      bank_details: {
        title: 'Банковские реквизиты',
        account_number: 'Номер счета',
        currency: 'Валюта',
        bank_name: 'Банк Наименование',
        bank_bik: 'Банк БИК',
        bank_current_account: 'Банк Корсчет',
        bank_country: 'Банк Страна',
        bank_city: 'Банк Город',
        bank_address: 'Банк Адрес',
        bank_phone: 'Банк Телефон',
        bank_swift: 'Банк СВИФТ'
      },
      contact_information: {
        title: 'Контактная информация',
        type: {
          title: 'Тип',
          address: 'Адрес',
          phone: 'Телефон',
          email: 'Адрес электронной почты'
        },
        category: {
          title: 'Вид',
          actual_address: 'Фактический адрес',
          legal_address: 'Юридический адрес',
          mobile_phone: 'Мобильный телефон',
          work_phone: 'Рабочий телефон'
        },
        index: 'Индекс',
        country: 'Страна / Код страны',
        region: 'Область',
        town: 'Город',
        street: 'Улица',
        building: 'Дом',
        office_apartment: 'Офис / Квартира'
      },
      counterparty_bank_cards: {
        title: 'Банковские карты контрагента',
        card_number: 'Номер карты',
        card_holder_name: 'Имя держателя карты',
        card_expiry_date: 'Дата истечения срока действия карты',
        national_payment_instrument: 'Это национальный платежный инструмент',
        international_payment_card: 'Это международная платежная карта'
      },
      created_successfully: 'Дистрибьютор создан!',
      updated_successfully: 'Дистрибьютор обновлен!',
      vat: {
        title: 'НДС'
      }
    },
    countries: {
      at: 'Австрия',
      be: 'Бельгия',
      bg: 'Болгария',
      gb: 'Великобритания',
      hu: 'Венгрия',
      dk: 'Дания',
      de: 'Германия',
      gr: 'Греция',
      ie: 'Ирландия',
      es: 'Испания',
      it: 'Италия',
      cy: 'Кипр',
      lv: 'Латвия',
      lt: 'Литва',
      lu: 'Люксембург',
      mt: 'Мальта',
      nl: 'Нидерланды',
      pl: 'Польша',
      pt: 'Португалия',
      ro: 'Румыния',
      sk: 'Словакия',
      si: 'Словения',
      fi: 'Финляндия',
      fr: 'Франция',
      hr: 'Хорватия',
      se: 'Швеция',
      cz: 'Чехия',
      ee: 'Эстония',
      by: 'Беларусь',
      ua: 'Украина',
      ru: 'Россия'
    }
  },
  en: {
    delete_confirm: 'Are you sure you want to delete?',
    delete: 'Delete',
    cancel: 'Cancel',
    validate_required: 'Field must be filled',
    materials: {
      title: 'Materials',
      add: 'Add material',
      name: 'Name',
      code: 'Code',
      maxWidth: 'Max width',
      maxHeight: 'Max height',
      perforation: 'Perforation',
      rotate: 'Rotation',
      saveded_successfully: 'Material has been saved!',
      deleted_successfully: 'Stuff has been deleted!'
    },
    collections: {
      title: 'Collections',
      add: 'Add collection',
      name: 'Name',
      code: 'Code',
      materials: 'Materials',
      hidePanels: 'Mechanism hiding',
      perforation: 'Perforation',
      combining: 'Combining',
      model: 'Model',
      min_distance_between_slats: 'Minimum distance between slats',
      individual_section_height: 'Individual section height',
      min_section_height: 'Minimum section height',
      saveded_successfully: 'The collection has been saved!',
      deleted_successfully: 'The collection has been deleted!'
    },
    colors: {
      title: 'Colors',
      add: 'Add color',
      name: 'Name',
      code: 'Code',
      category: 'Category view'
    },
    hidePanels: {
      title: 'Hiding',
      add: 'Add hiding',
      name: 'Name',
      code: 'Code'
    },
    faqs: {
      title: 'FAQs',
      add: 'Add FAQ',
      question: 'Question',
      answer: 'Answer',
      questionName: 'Question text',
      answerName: 'Answer text',
      category: 'FAQ category',
      saveded_successfully: 'Saved successfully',
      deleted_successfully: 'Deleted successfully',
      key: 'Key',
      add_category: 'Add category'
    },
    lk: {
      title: 'Personal account',
      save: 'Save',
      save_changes: 'Save changes',
      vat: 'VAT'
    },
    profile: {
      title: 'Profile',
      agent: 'Agent',
      first_name: 'Profile',
      name: 'Name',
      surname: 'Surname',
      work_phone: 'Office phone',
      mobile_phone: 'Mobile phone',
      sale: 'Max sale',
      configurator: 'Configurator',
      library: 'Library',
      certificates: 'Certificates',
      password: 'Password',
      change_password: 'Change password',
      old_password: 'Old password',
      new_password: 'New password',
      repeat_password: 'Repeat password',
      necessarily: 'Necessarily'
    },
    coefficients: {
      title: 'Coefficients',
      model: 'Model',
      collection: 'Collection',
      formula: 'Formula',
      count_section: 'from the number of sections',
      power_cost: 'power supply cost',
      battery_cost: 'battery cost',
      ch5_cost: 'remote control cost, 5 channels',
      ch15_cost: 'remote control cost, 15 channels'
    },
    currency: {
      title: 'Currency rate'
    },
    users: {
      title: 'Users',
      add: 'Add user',
      delete_confirm: 'Are you sure you want to delete?',
      delete: 'Delete',
      cancel: 'Cancel'
    },
    registration: {
      title: 'Registration'
    },
    edit: {
      title: 'Edit'
    },
    distributor: {
      title: 'Distributor',
      add: 'Add distributor',
      date_mask: 'dd/mm/yyyy',
      yes: 'Yes',
      no: 'No',
      gender: {
        title: 'Gender',
        male: 'Male',
        female: 'Female'
      },
      partner: {
        title: 'Partner / Counterparty',
        partner_type: 'Partner type',
        entity: 'Legal entity',
        physical_person: 'Physical person',
        legal_entity_non_resident: 'Legal entity non resident',
        individual_entrepreneur: 'Individual entrepreneur',
        name: 'Name',
        name_full: 'Full name',
        name_international: 'International name',
        inn: 'INN',
        kpp: 'KPP',
        kod_po_okpo: 'CodepoOKPO',
        ogrn: 'OGRN',
        country_of_registration: 'Country of registration',
        registration_date: 'Registration date',
        option_sending_e_check: 'Option to send e-check',
        do_not_send: 'Do not send',
        sms: 'Send SMS',
        email: 'Send Email'
      },
      physical_person: {
        title: 'Individual',
        fname: 'Name',
        sname: 'Last name',
        patronymic: 'Patronymic',
        birthday: 'Date of birth',
        inn: 'INN'
      },
      contact_person: {
        title: 'Contact person',
        fname: 'Name',
        sname: 'Last name',
        patronymic: 'Patronymic',
        phone: 'Phone',
        email: 'Email',
        sex: 'Sex',
        position: 'Position'
      },
      bank_details: {
        title: 'Bank details',
        account_number: 'Account number',
        currency: 'Currency',
        bank_name: 'Bank Name',
        bank_bik: 'Bank BIK',
        bank_current_account: 'Bank Correspondent',
        bank_country: 'Bank Country',
        bank_city: 'Bank City',
        bank_address: 'Bank Address',
        bank_phone: 'Bank Phone',
        bank_swift: 'Bank SWIFT'
      },
      contact_information: {
        title: 'Contact information',
        type: {
          title: 'Type',
          address: 'Address',
          phone: 'Phone',
          email: 'Email address'
        },
        category: {
          title: 'View',
          actual_address: 'Actual address',
          legal_address: 'Legal address',
          mobile_phone: 'Mobile phone',
          work_phone: 'Work phone'
        },
        index: 'Index',
        country: 'Country / Country Code',
        region: 'Region',
        town: 'City',
        street: 'Street',
        building: 'House',
        office_apartment: 'Office / Apartment'
      },
      counterparty_bank_cards: {
        title: 'Bank cards of the counterparty',
        card_number: 'Card number',
        card_holder_name: 'Cardholder name',
        card_expiry_date: 'Card expiration date',
        national_payment_instrument: 'This is a national payment instrument',
        international_payment_card: 'This is an international payment card'
      },
      created_successfully: 'Distributor created!',
      updated_successfully: 'Distributor updated!',
      vat: {
        title: 'VAT'
      }
    },
    countries: {
      at: 'Austria',
      be: 'Belgium',
      bg: 'Bulgaria',
      gb: 'UK',
      hu: 'Hungary',
      dk: 'Denmark',
      de: 'Germany',
      gr: 'Greece',
      ie: 'Ireland',
      es: 'Spain',
      it: 'Italy',
      cy: 'Cyprus',
      lv: 'Latvia',
      lt: 'Lithuania',
      lu: 'Luxembourg',
      mt: 'Malta',
      nl: 'Netherlands',
      pl: 'Poland',
      pt: 'Portugal',
      ro: 'Romania',
      sk: 'Slovakia',
      si: 'Slovenia',
      fi: 'Finland',
      fr: 'France',
      hr: 'Croatia',
      se: 'Sweden',
      cz: 'Czech Republic',
      ee: 'Estonia',
      by: 'Belarus',
      ua: 'Ukraine',
      ru: 'Russia'
    }
  }
};

const locale = dutchedVersion ? 'en-US' : 'ru-RU';

const i18n = new VueI18n({
  locale,
  silentFallbackWarn: true, // disable console warn
  messages // set locale messages
});

export default i18n;
