<template>
  <div class="delete-color">
    <h1>{{ $t('delete_confirm') }}</h1>
    <div class="button-container">
      <button class="button-submit delete-btn" type="submit" @click="deletecolor">
        {{ $t('delete') }}
      </button>

      <router-link class="color-add" to="/colors">
        <button class="button-submit delete-btn" type="submit">
          {{ $t('cancel') }}
        </button>
      </router-link>
    </div>
    <div class="error">{{ error_message }}</div>
    <div class="success">{{ success_message }}</div>
  </div>
</template>

<script>
import { api } from '@/api';
import { errorHandler } from '@/mixins';

export default {
  name: 'ColorDelete',
  components: {},
  mixins: [errorHandler],
  data() {
    return {
      colorId: this.$route.params.id,
      error_message: '',
      success_message: ''
    };
  },
  methods: {
    deletecolor() {
      api
        .delete(`/colors/${this.colorId}`)
        .then((res) => {
          if (!res || res.error || res.message) {
            this.error_message = 'Failed to delete color';
            return;
          }
          this.success_message = 'color deleted successfully';
          window.setTimeout(this.redirectTocolors, 1000);
        })
        .catch((error) => {
          console.error('error', error);
          this.error_message = 'Failed to delete collection';
        });
    },
    redirectTocolors() {
      window.location.href = `${window.location.origin}/colors`;
    }
  }
};
</script>

<style lang="scss">
.button-container {
  display: flex;
  justify-content: space-between;
}

.delete-btn:hover {
  cursor: pointer;
}

.delete-color {
  &__line {
    display: flex;
  }

  .error {
    margin-top: 20px;
    color: red;
  }

  .success {
    margin-top: 20px;
    color: green;
  }
}
</style>
