<template>
  <div class="category-faq">
    <form id="category-faq" class="category-faq__form" @submit.prevent="registerFaqCategory">
      <text-input
        :name="$t('profile.name')"
        :placeholder="$t('profile.name')"
        :value="name"
        @change="changeInput('name', $event)"
      />
      <text-input
        :name="$t('faqs.key')"
        :placeholder="$t('faqs.key') + ' en'"
        :value="value"
        @change="changeInput('value', $event)"
      />
    </form>

    <button class="button-submit" type="submit" form="category-faq">{{ $t('lk.save') }}</button>
    <div class="message">{{ message }}</div>
  </div>
</template>

<script>
import { api } from '@/api';
import TextInput from '../../inputs/text-input/TextInput.vue';

export default {
  name: 'CategoryFaq',
  components: { TextInput },
  data() {
    return {
      message: '',
      name: '',
      value: ''
    };
  },
  methods: {
    changeInput(key, value) {
      this[key] = value;
      this.message = '';
    },
    registerFaqCategory() {
      const data = {
        name: this.name,
        value: this.value
      };

      api
        .post('/category-faq', JSON.stringify(data))
        .then((res) => {
          if (res.message) return this.messageHandler(`Error ${res.message}`);
          window.setTimeout(this.redirectToFaqs, 1000);
          return this.messageHandler(this.$t('faqs.saveded_successfully'));
        })
        .catch((error) => {
          this.messageHandler(`Error ${error}`);
        });
    },
    redirectToFaqs() {
      window.location.href = `${window.location.origin}/faqs`;
    }
  }
};
</script>

<style lang="scss">
.category-faq {
  &__form {
    display: flex;
    column-gap: 20px;
    input,
    .input__container {
      width: 100%;
    }
  }
}
</style>
